import { DemoIds } from './demoIds'
import {
    Color,
    initialLoopSelectorState,
    Instruments,
    KeyType,
    PatternType,
    ProjectStateModel,
    ScaleType,
} from '@tekbox-coco/midiative-commons'

export const BalladDemoProject: ProjectStateModel = {
    id: DemoIds.BALLAD,
    schemaVersion: 1,
    name: 'Ballad-Demo',
    demo: true,
    bpm: 106,
    timeSignatureNumerator: 4,
    timeSignatureDenominator: 4,
    key: KeyType.C,
    scale: ScaleType.PENT_MAJ,
    loopSelector: initialLoopSelectorState,
    created: 0,
    lastEdit: 0,
    patterns: {
        ids: [
            '963b3e49-ec32-4673-9dec-bfc9f5d70feb',
            'e11a726a-bba2-4df7-91ff-74cd99357255',
            'e6bb125f-95b4-46aa-9b6c-2d2a89cbcb80',
            '6fc0d179-1428-451e-a78d-6976abba1e4a',
            '44b1d986-fff2-4ab8-b98d-49f6de6c806b',
            '1c03d821-b9df-486b-898b-d07967df8a25',
            '93b0c020-1a26-4f15-a65a-7b3b45b73de5',
            '8462bca7-25c8-479b-9441-404908c6dea1',
            '30c1946d-12cf-4750-9a8c-f6eae09eb0a8',
            'e09f69d5-f516-4784-94f7-3d29be2840ef',
            'ded8bf4e-892a-483b-b634-b14e47fc42e7',
            '9cd2136b-ed6e-4637-abc1-12ac6bf111e9',
            '8ce92bc2-b0b5-473f-8db4-1bbe571d8c38',
        ],
        entities: {
            '963b3e49-ec32-4673-9dec-bfc9f5d70feb': {
                id: '963b3e49-ec32-4673-9dec-bfc9f5d70feb',
                lastResolution: 8,
                lengthIRN: 1536,
                notes: [
                    {
                        id: '8361f2b7-8a2d-47a8-af4e-2621189f0682',
                        startIRN: 0,
                        key: 69,
                        lengthIRN: 48,
                        velocity: 127,
                    },
                    {
                        id: '10ef6764-74b6-41ba-a7f2-d65effdb81c7',
                        startIRN: 0,
                        key: 72,
                        lengthIRN: 48,
                        velocity: 127,
                    },
                    {
                        id: 'f4895fcb-f344-4cf4-852a-d900dc5d529a',
                        startIRN: 0,
                        key: 76,
                        lengthIRN: 48,
                        velocity: 127,
                    },
                    {
                        id: 'ec148f53-695a-41ff-87f8-7c096c786a06',
                        startIRN: 48,
                        key: 69,
                        lengthIRN: 48,
                        velocity: 127,
                    },
                    {
                        id: '6d4a8fb2-0ffc-4d4d-a48d-6ef197636f79',
                        startIRN: 48,
                        key: 72,
                        lengthIRN: 48,
                        velocity: 127,
                    },
                    {
                        id: '0c67c5f3-a46a-4af3-9abe-edd9f15cab40',
                        startIRN: 48,
                        key: 76,
                        lengthIRN: 48,
                        velocity: 127,
                    },
                    {
                        id: 'c3894e13-9c28-4391-8dc8-e61fa1381ad5',
                        startIRN: 96,
                        key: 69,
                        lengthIRN: 48,
                        velocity: 127,
                    },
                    {
                        id: 'a3acba14-1236-44cd-a3c7-816ed79fd741',
                        startIRN: 96,
                        key: 72,
                        lengthIRN: 48,
                        velocity: 127,
                    },
                    {
                        id: 'f26b0d41-7602-4f16-bbaa-78837fff5eaa',
                        startIRN: 96,
                        key: 76,
                        lengthIRN: 48,
                        velocity: 127,
                    },
                    {
                        id: '42de240f-7031-4c1d-b1f3-30679958fa07',
                        startIRN: 144,
                        key: 69,
                        lengthIRN: 48,
                        velocity: 127,
                    },
                    {
                        id: '84b0a8c0-0c47-4fb0-919d-9499c744897d',
                        startIRN: 144,
                        key: 72,
                        lengthIRN: 48,
                        velocity: 127,
                    },
                    {
                        id: '1c61bae4-a43b-44b6-b79d-7c94c456429e',
                        startIRN: 144,
                        key: 76,
                        lengthIRN: 48,
                        velocity: 127,
                    },
                    {
                        id: '9f0a143f-4a77-4169-ab9a-4dbbc3528af9',
                        startIRN: 192,
                        key: 69,
                        lengthIRN: 48,
                        velocity: 127,
                    },
                    {
                        id: 'de4f0966-0786-48c5-8907-331acc6aa83e',
                        startIRN: 192,
                        key: 72,
                        lengthIRN: 48,
                        velocity: 127,
                    },
                    {
                        id: 'de0eb9ba-b540-464a-95bd-80a4f0ebdb9a',
                        startIRN: 192,
                        key: 76,
                        lengthIRN: 48,
                        velocity: 127,
                    },
                    {
                        id: '98fc7d47-0ab9-4678-98ee-ffdaa02a8fd0',
                        startIRN: 384,
                        key: 74,
                        lengthIRN: 48,
                        velocity: 127,
                    },
                    {
                        id: '9f6228d7-65e8-4b21-a00b-1702afd9d57d',
                        startIRN: 384,
                        key: 77,
                        lengthIRN: 48,
                        velocity: 127,
                    },
                    {
                        id: '5033e0ef-ea01-4187-950e-c856849fc492',
                        startIRN: 384,
                        key: 81,
                        lengthIRN: 48,
                        velocity: 127,
                    },
                    {
                        id: 'd2ebd4ac-d73b-468d-a40c-ec27c1b19355',
                        startIRN: 432,
                        key: 74,
                        lengthIRN: 48,
                        velocity: 127,
                    },
                    {
                        id: 'c280b795-2f11-40e9-bbe4-d8f2a2f70a04',
                        startIRN: 432,
                        key: 77,
                        lengthIRN: 48,
                        velocity: 127,
                    },
                    {
                        id: 'b84c6511-57b5-4926-a248-21a6ca4b7c7e',
                        startIRN: 432,
                        key: 81,
                        lengthIRN: 48,
                        velocity: 127,
                    },
                    {
                        id: '4a045136-c18c-46e4-a733-bd6e7ed48bab',
                        startIRN: 480,
                        key: 74,
                        lengthIRN: 48,
                        velocity: 127,
                    },
                    {
                        id: '9d3bd6cb-9cce-4e8e-a10d-398952d876bd',
                        startIRN: 480,
                        key: 77,
                        lengthIRN: 48,
                        velocity: 127,
                    },
                    {
                        id: '4dc52ec4-6983-4072-a3f5-d24963e19dae',
                        startIRN: 480,
                        key: 81,
                        lengthIRN: 48,
                        velocity: 127,
                    },
                    {
                        id: '6ca11a76-5919-4744-810b-e868e7ca1555',
                        startIRN: 528,
                        key: 74,
                        lengthIRN: 48,
                        velocity: 127,
                    },
                    {
                        id: '0ac09579-79a5-49bf-811f-24163ba397b6',
                        startIRN: 528,
                        key: 77,
                        lengthIRN: 48,
                        velocity: 127,
                    },
                    {
                        id: '1e21154a-1830-4e9a-9530-6c6d350a7f76',
                        startIRN: 528,
                        key: 81,
                        lengthIRN: 48,
                        velocity: 127,
                    },
                    {
                        id: 'a6257876-e194-4a58-b91c-5215d632187f',
                        startIRN: 576,
                        key: 74,
                        lengthIRN: 48,
                        velocity: 127,
                    },
                    {
                        id: 'e0a2b164-8cf6-4e40-800c-f90d833617e0',
                        startIRN: 576,
                        key: 77,
                        lengthIRN: 48,
                        velocity: 127,
                    },
                    {
                        id: 'a94621dc-8b44-418a-9d26-29bb26fb5f16',
                        startIRN: 576,
                        key: 81,
                        lengthIRN: 48,
                        velocity: 127,
                    },
                    {
                        id: 'f41663c1-307d-499e-aa67-ae5688372ab8',
                        startIRN: 768,
                        key: 72,
                        lengthIRN: 48,
                        velocity: 127,
                    },
                    {
                        id: 'f3afaa73-542d-4e26-ae4c-dbddabe3e470',
                        startIRN: 768,
                        key: 79,
                        lengthIRN: 48,
                        velocity: 127,
                    },
                    {
                        id: 'fb257529-b9b7-497c-9a2b-090476556d21',
                        startIRN: 816,
                        key: 72,
                        lengthIRN: 48,
                        velocity: 127,
                    },
                    {
                        id: 'd45f436d-a3ea-42f0-bd33-8f34bbee7990',
                        startIRN: 816,
                        key: 79,
                        lengthIRN: 48,
                        velocity: 127,
                    },
                    {
                        id: 'adf122a3-6d26-41ac-b62a-135603101bb2',
                        startIRN: 864,
                        key: 72,
                        lengthIRN: 48,
                        velocity: 127,
                    },
                    {
                        id: '3ffc97a5-2cea-4a3b-b0b4-e38fe79b0a32',
                        startIRN: 864,
                        key: 79,
                        lengthIRN: 48,
                        velocity: 127,
                    },
                    {
                        id: '1a47ef35-5a08-4575-b160-969ab53b9244',
                        startIRN: 912,
                        key: 72,
                        lengthIRN: 48,
                        velocity: 127,
                    },
                    {
                        id: '102cf464-4d5f-4136-9bf3-5282739db7f8',
                        startIRN: 912,
                        key: 79,
                        lengthIRN: 48,
                        velocity: 127,
                    },
                    {
                        id: '676f64bb-7f2d-4a1b-8c4e-56c01ada715e',
                        startIRN: 960,
                        key: 72,
                        lengthIRN: 48,
                        velocity: 127,
                    },
                    {
                        id: 'b6b615b0-0316-4606-aba3-9c0b1a7cee4d',
                        startIRN: 960,
                        key: 79,
                        lengthIRN: 48,
                        velocity: 127,
                    },
                    {
                        id: '3a980c9a-cf6c-4d2c-b3b9-161c18f019fe',
                        startIRN: 1152,
                        key: 67,
                        lengthIRN: 48,
                        velocity: 127,
                    },
                    {
                        id: '5929301e-e8e2-457f-8105-bede9cc5be3c',
                        startIRN: 1152,
                        key: 74,
                        lengthIRN: 48,
                        velocity: 127,
                    },
                    {
                        id: '9396c2b1-c798-4dcf-a380-60735890882d',
                        startIRN: 1200,
                        key: 67,
                        lengthIRN: 48,
                        velocity: 127,
                    },
                    {
                        id: '1e1c1784-99b7-4e4b-9f41-56e807b0b40b',
                        startIRN: 1200,
                        key: 74,
                        lengthIRN: 48,
                        velocity: 127,
                    },
                    {
                        id: 'e6097543-30d9-4cc1-93ac-9eb989383698',
                        startIRN: 1248,
                        key: 67,
                        lengthIRN: 48,
                        velocity: 127,
                    },
                    {
                        id: 'd4f1139d-60bd-4bae-ab17-6304dc324028',
                        startIRN: 1248,
                        key: 74,
                        lengthIRN: 48,
                        velocity: 127,
                    },
                    {
                        id: 'fa79c90b-05b7-457c-8890-36c22c553d29',
                        startIRN: 1296,
                        key: 67,
                        lengthIRN: 48,
                        velocity: 127,
                    },
                    {
                        id: '3f68db78-d17a-467a-849f-17a85c4b60d8',
                        startIRN: 1296,
                        key: 74,
                        lengthIRN: 48,
                        velocity: 127,
                    },
                    {
                        id: 'cc20eb18-0e3a-40e0-abad-95607537e0c2',
                        startIRN: 1344,
                        key: 67,
                        lengthIRN: 48,
                        velocity: 127,
                    },
                    {
                        id: '70a1c1c8-c6d3-48c7-8148-8a3ed480fe7d',
                        startIRN: 1344,
                        key: 74,
                        lengthIRN: 48,
                        velocity: 127,
                    },
                ],
                color: Color.CYAN,
                type: PatternType.PianoRollPattern,
            },
            'e11a726a-bba2-4df7-91ff-74cd99357255': {
                id: 'e11a726a-bba2-4df7-91ff-74cd99357255',
                lastResolution: 4,
                lengthIRN: 1536,
                notes: [
                    {
                        id: '8df65646-67f6-45b6-a6bd-8533d4a6e23b',
                        key: 36,
                        startIRN: 0,
                        velocity: 127,
                        lengthIRN: 96,
                    },
                    {
                        id: 'dc93afb5-d05b-4101-b275-c5499be3c94c',
                        key: 38,
                        startIRN: 192,
                        velocity: 127,
                        lengthIRN: 96,
                    },
                    {
                        id: '8ec9bb3e-0387-4f6f-81fc-10bfff7bdabb',
                        key: 36,
                        startIRN: 384,
                        velocity: 127,
                        lengthIRN: 96,
                    },
                    {
                        id: '15eb7f0a-9672-4886-a3ec-740e988d2200',
                        key: 38,
                        startIRN: 576,
                        velocity: 127,
                        lengthIRN: 96,
                    },
                    {
                        id: '1f2ecbea-3369-4aa4-8248-23665b03d363',
                        key: 36,
                        startIRN: 768,
                        velocity: 127,
                        lengthIRN: 96,
                    },
                    {
                        id: '0fdcfad4-08a6-43f7-a541-cf48eb7a98bc',
                        key: 38,
                        startIRN: 960,
                        velocity: 127,
                        lengthIRN: 96,
                    },
                    {
                        id: 'fdf395d3-37ad-40b3-94a8-80577272da0b',
                        key: 36,
                        startIRN: 1152,
                        velocity: 127,
                        lengthIRN: 96,
                    },
                    {
                        id: '87944edd-f700-4310-a817-c08cccc841d9',
                        key: 38,
                        startIRN: 1344,
                        velocity: 127,
                        lengthIRN: 96,
                    },
                ],
                color: Color.GREEN,
                type: PatternType.SequencerPattern,
            },
            'e6bb125f-95b4-46aa-9b6c-2d2a89cbcb80': {
                id: 'e6bb125f-95b4-46aa-9b6c-2d2a89cbcb80',
                lastResolution: 8,
                lengthIRN: 1536,
                notes: [
                    {
                        id: '8361f2b7-8a2d-47a8-af4e-2621189f0682',
                        startIRN: 0,
                        key: 69,
                        lengthIRN: 48,
                        velocity: 127,
                    },
                    {
                        id: '10ef6764-74b6-41ba-a7f2-d65effdb81c7',
                        startIRN: 0,
                        key: 72,
                        lengthIRN: 48,
                        velocity: 127,
                    },
                    {
                        id: 'f4895fcb-f344-4cf4-852a-d900dc5d529a',
                        startIRN: 0,
                        key: 76,
                        lengthIRN: 48,
                        velocity: 127,
                    },
                    {
                        id: 'ec148f53-695a-41ff-87f8-7c096c786a06',
                        startIRN: 48,
                        key: 69,
                        lengthIRN: 48,
                        velocity: 127,
                    },
                    {
                        id: '6d4a8fb2-0ffc-4d4d-a48d-6ef197636f79',
                        startIRN: 48,
                        key: 72,
                        lengthIRN: 48,
                        velocity: 127,
                    },
                    {
                        id: '0c67c5f3-a46a-4af3-9abe-edd9f15cab40',
                        startIRN: 48,
                        key: 76,
                        lengthIRN: 48,
                        velocity: 127,
                    },
                    {
                        id: 'c3894e13-9c28-4391-8dc8-e61fa1381ad5',
                        startIRN: 96,
                        key: 69,
                        lengthIRN: 48,
                        velocity: 127,
                    },
                    {
                        id: 'a3acba14-1236-44cd-a3c7-816ed79fd741',
                        startIRN: 96,
                        key: 72,
                        lengthIRN: 48,
                        velocity: 127,
                    },
                    {
                        id: 'f26b0d41-7602-4f16-bbaa-78837fff5eaa',
                        startIRN: 96,
                        key: 76,
                        lengthIRN: 48,
                        velocity: 127,
                    },
                    {
                        id: '42de240f-7031-4c1d-b1f3-30679958fa07',
                        startIRN: 144,
                        key: 69,
                        lengthIRN: 48,
                        velocity: 127,
                    },
                    {
                        id: '84b0a8c0-0c47-4fb0-919d-9499c744897d',
                        startIRN: 144,
                        key: 72,
                        lengthIRN: 48,
                        velocity: 127,
                    },
                    {
                        id: '1c61bae4-a43b-44b6-b79d-7c94c456429e',
                        startIRN: 144,
                        key: 76,
                        lengthIRN: 48,
                        velocity: 127,
                    },
                    {
                        id: '9f0a143f-4a77-4169-ab9a-4dbbc3528af9',
                        startIRN: 192,
                        key: 69,
                        lengthIRN: 48,
                        velocity: 127,
                    },
                    {
                        id: 'de4f0966-0786-48c5-8907-331acc6aa83e',
                        startIRN: 192,
                        key: 72,
                        lengthIRN: 48,
                        velocity: 127,
                    },
                    {
                        id: 'de0eb9ba-b540-464a-95bd-80a4f0ebdb9a',
                        startIRN: 192,
                        key: 76,
                        lengthIRN: 48,
                        velocity: 127,
                    },
                    {
                        id: '98fc7d47-0ab9-4678-98ee-ffdaa02a8fd0',
                        startIRN: 384,
                        key: 74,
                        lengthIRN: 48,
                        velocity: 127,
                    },
                    {
                        id: '9f6228d7-65e8-4b21-a00b-1702afd9d57d',
                        startIRN: 384,
                        key: 77,
                        lengthIRN: 48,
                        velocity: 127,
                    },
                    {
                        id: '5033e0ef-ea01-4187-950e-c856849fc492',
                        startIRN: 384,
                        key: 81,
                        lengthIRN: 48,
                        velocity: 127,
                    },
                    {
                        id: 'd2ebd4ac-d73b-468d-a40c-ec27c1b19355',
                        startIRN: 432,
                        key: 74,
                        lengthIRN: 48,
                        velocity: 127,
                    },
                    {
                        id: 'c280b795-2f11-40e9-bbe4-d8f2a2f70a04',
                        startIRN: 432,
                        key: 77,
                        lengthIRN: 48,
                        velocity: 127,
                    },
                    {
                        id: 'b84c6511-57b5-4926-a248-21a6ca4b7c7e',
                        startIRN: 432,
                        key: 81,
                        lengthIRN: 48,
                        velocity: 127,
                    },
                    {
                        id: '4a045136-c18c-46e4-a733-bd6e7ed48bab',
                        startIRN: 480,
                        key: 74,
                        lengthIRN: 48,
                        velocity: 127,
                    },
                    {
                        id: '9d3bd6cb-9cce-4e8e-a10d-398952d876bd',
                        startIRN: 480,
                        key: 77,
                        lengthIRN: 48,
                        velocity: 127,
                    },
                    {
                        id: '4dc52ec4-6983-4072-a3f5-d24963e19dae',
                        startIRN: 480,
                        key: 81,
                        lengthIRN: 48,
                        velocity: 127,
                    },
                    {
                        id: '6ca11a76-5919-4744-810b-e868e7ca1555',
                        startIRN: 528,
                        key: 74,
                        lengthIRN: 48,
                        velocity: 127,
                    },
                    {
                        id: '0ac09579-79a5-49bf-811f-24163ba397b6',
                        startIRN: 528,
                        key: 77,
                        lengthIRN: 48,
                        velocity: 127,
                    },
                    {
                        id: '1e21154a-1830-4e9a-9530-6c6d350a7f76',
                        startIRN: 528,
                        key: 81,
                        lengthIRN: 48,
                        velocity: 127,
                    },
                    {
                        id: 'a6257876-e194-4a58-b91c-5215d632187f',
                        startIRN: 576,
                        key: 74,
                        lengthIRN: 48,
                        velocity: 127,
                    },
                    {
                        id: 'e0a2b164-8cf6-4e40-800c-f90d833617e0',
                        startIRN: 576,
                        key: 77,
                        lengthIRN: 48,
                        velocity: 127,
                    },
                    {
                        id: 'a94621dc-8b44-418a-9d26-29bb26fb5f16',
                        startIRN: 576,
                        key: 81,
                        lengthIRN: 48,
                        velocity: 127,
                    },
                    {
                        id: 'f41663c1-307d-499e-aa67-ae5688372ab8',
                        startIRN: 768,
                        key: 72,
                        lengthIRN: 48,
                        velocity: 127,
                    },
                    {
                        id: 'f3afaa73-542d-4e26-ae4c-dbddabe3e470',
                        startIRN: 768,
                        key: 79,
                        lengthIRN: 48,
                        velocity: 127,
                    },
                    {
                        id: 'fb257529-b9b7-497c-9a2b-090476556d21',
                        startIRN: 816,
                        key: 72,
                        lengthIRN: 48,
                        velocity: 127,
                    },
                    {
                        id: 'd45f436d-a3ea-42f0-bd33-8f34bbee7990',
                        startIRN: 816,
                        key: 79,
                        lengthIRN: 48,
                        velocity: 127,
                    },
                    {
                        id: 'adf122a3-6d26-41ac-b62a-135603101bb2',
                        startIRN: 864,
                        key: 72,
                        lengthIRN: 48,
                        velocity: 127,
                    },
                    {
                        id: '3ffc97a5-2cea-4a3b-b0b4-e38fe79b0a32',
                        startIRN: 864,
                        key: 79,
                        lengthIRN: 48,
                        velocity: 127,
                    },
                    {
                        id: '1a47ef35-5a08-4575-b160-969ab53b9244',
                        startIRN: 912,
                        key: 72,
                        lengthIRN: 48,
                        velocity: 127,
                    },
                    {
                        id: '102cf464-4d5f-4136-9bf3-5282739db7f8',
                        startIRN: 912,
                        key: 79,
                        lengthIRN: 48,
                        velocity: 127,
                    },
                    {
                        id: '676f64bb-7f2d-4a1b-8c4e-56c01ada715e',
                        startIRN: 960,
                        key: 72,
                        lengthIRN: 48,
                        velocity: 127,
                    },
                    {
                        id: 'b6b615b0-0316-4606-aba3-9c0b1a7cee4d',
                        startIRN: 960,
                        key: 79,
                        lengthIRN: 48,
                        velocity: 127,
                    },
                    {
                        id: '3a980c9a-cf6c-4d2c-b3b9-161c18f019fe',
                        startIRN: 1152,
                        key: 67,
                        lengthIRN: 48,
                        velocity: 127,
                    },
                    {
                        id: '5929301e-e8e2-457f-8105-bede9cc5be3c',
                        startIRN: 1152,
                        key: 74,
                        lengthIRN: 48,
                        velocity: 127,
                    },
                    {
                        id: '9396c2b1-c798-4dcf-a380-60735890882d',
                        startIRN: 1200,
                        key: 67,
                        lengthIRN: 48,
                        velocity: 127,
                    },
                    {
                        id: '1e1c1784-99b7-4e4b-9f41-56e807b0b40b',
                        startIRN: 1200,
                        key: 74,
                        lengthIRN: 48,
                        velocity: 127,
                    },
                    {
                        id: 'e6097543-30d9-4cc1-93ac-9eb989383698',
                        startIRN: 1248,
                        key: 67,
                        lengthIRN: 48,
                        velocity: 127,
                    },
                    {
                        id: 'd4f1139d-60bd-4bae-ab17-6304dc324028',
                        startIRN: 1248,
                        key: 74,
                        lengthIRN: 48,
                        velocity: 127,
                    },
                    {
                        id: 'fa79c90b-05b7-457c-8890-36c22c553d29',
                        startIRN: 1296,
                        key: 67,
                        lengthIRN: 48,
                        velocity: 127,
                    },
                    {
                        id: '3f68db78-d17a-467a-849f-17a85c4b60d8',
                        startIRN: 1296,
                        key: 74,
                        lengthIRN: 48,
                        velocity: 127,
                    },
                    {
                        id: 'cc20eb18-0e3a-40e0-abad-95607537e0c2',
                        startIRN: 1344,
                        key: 67,
                        lengthIRN: 48,
                        velocity: 127,
                    },
                    {
                        id: '70a1c1c8-c6d3-48c7-8148-8a3ed480fe7d',
                        startIRN: 1344,
                        key: 74,
                        lengthIRN: 48,
                        velocity: 127,
                    },
                ],
                color: Color.YELLOW,
                type: PatternType.PianoRollPattern,
            },
            '6fc0d179-1428-451e-a78d-6976abba1e4a': {
                id: '6fc0d179-1428-451e-a78d-6976abba1e4a',
                lastResolution: 8,
                lengthIRN: 1536,
                notes: [
                    {
                        id: 'd3a10eb8-31ad-4523-94a1-095957a79c0f',
                        startIRN: 0,
                        key: 60,
                        lengthIRN: 48,
                        velocity: 127,
                    },
                    {
                        id: '10d6dfb6-797a-47e9-87e1-6464cacdf821',
                        startIRN: 48,
                        key: 62,
                        lengthIRN: 48,
                        velocity: 127,
                    },
                    {
                        id: 'f8810fae-b958-4c0b-906a-a9f1537b291b',
                        startIRN: 192,
                        key: 67,
                        lengthIRN: 48,
                        velocity: 127,
                    },
                    {
                        id: 'f87b44f8-c34a-4562-abb1-3537772d394c',
                        startIRN: 240,
                        key: 69,
                        lengthIRN: 48,
                        velocity: 127,
                    },
                    {
                        id: 'e3fe050d-1633-427d-a645-e81543369872',
                        startIRN: 384,
                        key: 64,
                        lengthIRN: 48,
                        velocity: 127,
                    },
                    {
                        id: '68cb7dfe-8b85-4221-b3a8-d4777ee48fc1',
                        startIRN: 432,
                        key: 67,
                        lengthIRN: 48,
                        velocity: 127,
                    },
                    {
                        id: '71cd8c3d-7757-40bc-b267-25243664ce40',
                        startIRN: 576,
                        key: 67,
                        lengthIRN: 48,
                        velocity: 127,
                    },
                    {
                        id: '584f6b6f-d1a8-4c3e-bedf-ce5481786b4c',
                        startIRN: 624,
                        key: 64,
                        lengthIRN: 48,
                        velocity: 127,
                    },
                    {
                        id: 'a0586fed-950e-4f1c-a354-fd86d92a578a',
                        startIRN: 768,
                        key: 64,
                        lengthIRN: 48,
                        velocity: 127,
                    },
                    {
                        id: '534f2647-2c65-414d-bb34-66c6da6719f1',
                        startIRN: 816,
                        key: 64,
                        lengthIRN: 48,
                        velocity: 127,
                    },
                    {
                        id: 'c766edec-1543-4301-af8f-a11bf8dc5802',
                        startIRN: 864,
                        key: 69,
                        lengthIRN: 48,
                        velocity: 127,
                    },
                    {
                        id: 'f9730efa-53af-436a-8ef0-4a5f3d9f2fba',
                        startIRN: 912,
                        key: 67,
                        lengthIRN: 48,
                        velocity: 127,
                    },
                    {
                        id: '6618409d-5365-4bee-aa91-89baf3419d1f',
                        startIRN: 1056,
                        key: 64,
                        lengthIRN: 48,
                        velocity: 127,
                    },
                    {
                        id: '9fe9d589-b87a-4a0a-b646-f7c1f257c96b',
                        startIRN: 1104,
                        key: 67,
                        lengthIRN: 48,
                        velocity: 127,
                    },
                    {
                        id: 'b429a84f-9c26-4566-86ad-83e0d4df31f7',
                        startIRN: 1296,
                        key: 72,
                        lengthIRN: 48,
                        velocity: 127,
                    },
                    {
                        id: 'af4bcdfa-52ed-45d2-9178-e2c6a243799f',
                        startIRN: 1344,
                        key: 72,
                        lengthIRN: 48,
                        velocity: 127,
                    },
                    {
                        id: 'd0302be7-d1f6-49ed-86e1-d798af60b1cb',
                        startIRN: 1440,
                        key: 69,
                        lengthIRN: 48,
                        velocity: 127,
                    },
                    {
                        id: 'f5b233f4-5d44-4a44-add1-b1735d8150dc',
                        startIRN: 1488,
                        key: 67,
                        lengthIRN: 48,
                        velocity: 127,
                    },
                ],
                color: Color.ORANGE,
                type: PatternType.PianoRollPattern,
            },
            '44b1d986-fff2-4ab8-b98d-49f6de6c806b': {
                id: '44b1d986-fff2-4ab8-b98d-49f6de6c806b',
                lastResolution: 8,
                lengthIRN: 1536,
                notes: [
                    {
                        id: '8361f2b7-8a2d-47a8-af4e-2621189f0682',
                        startIRN: 0,
                        key: 69,
                        lengthIRN: 48,
                        velocity: 127,
                    },
                    {
                        id: '10ef6764-74b6-41ba-a7f2-d65effdb81c7',
                        startIRN: 0,
                        key: 72,
                        lengthIRN: 48,
                        velocity: 127,
                    },
                    {
                        id: 'f4895fcb-f344-4cf4-852a-d900dc5d529a',
                        startIRN: 0,
                        key: 76,
                        lengthIRN: 48,
                        velocity: 127,
                    },
                    {
                        id: 'ec148f53-695a-41ff-87f8-7c096c786a06',
                        startIRN: 48,
                        key: 69,
                        lengthIRN: 48,
                        velocity: 127,
                    },
                    {
                        id: '6d4a8fb2-0ffc-4d4d-a48d-6ef197636f79',
                        startIRN: 48,
                        key: 72,
                        lengthIRN: 48,
                        velocity: 127,
                    },
                    {
                        id: '0c67c5f3-a46a-4af3-9abe-edd9f15cab40',
                        startIRN: 48,
                        key: 76,
                        lengthIRN: 48,
                        velocity: 127,
                    },
                    {
                        id: 'c3894e13-9c28-4391-8dc8-e61fa1381ad5',
                        startIRN: 96,
                        key: 69,
                        lengthIRN: 48,
                        velocity: 127,
                    },
                    {
                        id: 'a3acba14-1236-44cd-a3c7-816ed79fd741',
                        startIRN: 96,
                        key: 72,
                        lengthIRN: 48,
                        velocity: 127,
                    },
                    {
                        id: 'f26b0d41-7602-4f16-bbaa-78837fff5eaa',
                        startIRN: 96,
                        key: 76,
                        lengthIRN: 48,
                        velocity: 127,
                    },
                    {
                        id: '42de240f-7031-4c1d-b1f3-30679958fa07',
                        startIRN: 144,
                        key: 69,
                        lengthIRN: 48,
                        velocity: 127,
                    },
                    {
                        id: '84b0a8c0-0c47-4fb0-919d-9499c744897d',
                        startIRN: 144,
                        key: 72,
                        lengthIRN: 48,
                        velocity: 127,
                    },
                    {
                        id: '1c61bae4-a43b-44b6-b79d-7c94c456429e',
                        startIRN: 144,
                        key: 76,
                        lengthIRN: 48,
                        velocity: 127,
                    },
                    {
                        id: '9f0a143f-4a77-4169-ab9a-4dbbc3528af9',
                        startIRN: 192,
                        key: 69,
                        lengthIRN: 48,
                        velocity: 127,
                    },
                    {
                        id: 'de4f0966-0786-48c5-8907-331acc6aa83e',
                        startIRN: 192,
                        key: 72,
                        lengthIRN: 48,
                        velocity: 127,
                    },
                    {
                        id: 'de0eb9ba-b540-464a-95bd-80a4f0ebdb9a',
                        startIRN: 192,
                        key: 76,
                        lengthIRN: 48,
                        velocity: 127,
                    },
                    {
                        id: '98fc7d47-0ab9-4678-98ee-ffdaa02a8fd0',
                        startIRN: 384,
                        key: 74,
                        lengthIRN: 48,
                        velocity: 127,
                    },
                    {
                        id: '9f6228d7-65e8-4b21-a00b-1702afd9d57d',
                        startIRN: 384,
                        key: 77,
                        lengthIRN: 48,
                        velocity: 127,
                    },
                    {
                        id: '5033e0ef-ea01-4187-950e-c856849fc492',
                        startIRN: 384,
                        key: 81,
                        lengthIRN: 48,
                        velocity: 127,
                    },
                    {
                        id: 'd2ebd4ac-d73b-468d-a40c-ec27c1b19355',
                        startIRN: 432,
                        key: 74,
                        lengthIRN: 48,
                        velocity: 127,
                    },
                    {
                        id: 'c280b795-2f11-40e9-bbe4-d8f2a2f70a04',
                        startIRN: 432,
                        key: 77,
                        lengthIRN: 48,
                        velocity: 127,
                    },
                    {
                        id: 'b84c6511-57b5-4926-a248-21a6ca4b7c7e',
                        startIRN: 432,
                        key: 81,
                        lengthIRN: 48,
                        velocity: 127,
                    },
                    {
                        id: '4a045136-c18c-46e4-a733-bd6e7ed48bab',
                        startIRN: 480,
                        key: 74,
                        lengthIRN: 48,
                        velocity: 127,
                    },
                    {
                        id: '9d3bd6cb-9cce-4e8e-a10d-398952d876bd',
                        startIRN: 480,
                        key: 77,
                        lengthIRN: 48,
                        velocity: 127,
                    },
                    {
                        id: '4dc52ec4-6983-4072-a3f5-d24963e19dae',
                        startIRN: 480,
                        key: 81,
                        lengthIRN: 48,
                        velocity: 127,
                    },
                    {
                        id: '6ca11a76-5919-4744-810b-e868e7ca1555',
                        startIRN: 528,
                        key: 74,
                        lengthIRN: 48,
                        velocity: 127,
                    },
                    {
                        id: '0ac09579-79a5-49bf-811f-24163ba397b6',
                        startIRN: 528,
                        key: 77,
                        lengthIRN: 48,
                        velocity: 127,
                    },
                    {
                        id: '1e21154a-1830-4e9a-9530-6c6d350a7f76',
                        startIRN: 528,
                        key: 81,
                        lengthIRN: 48,
                        velocity: 127,
                    },
                    {
                        id: 'a6257876-e194-4a58-b91c-5215d632187f',
                        startIRN: 576,
                        key: 74,
                        lengthIRN: 48,
                        velocity: 127,
                    },
                    {
                        id: 'e0a2b164-8cf6-4e40-800c-f90d833617e0',
                        startIRN: 576,
                        key: 77,
                        lengthIRN: 48,
                        velocity: 127,
                    },
                    {
                        id: 'a94621dc-8b44-418a-9d26-29bb26fb5f16',
                        startIRN: 576,
                        key: 81,
                        lengthIRN: 48,
                        velocity: 127,
                    },
                    {
                        id: 'f41663c1-307d-499e-aa67-ae5688372ab8',
                        startIRN: 768,
                        key: 72,
                        lengthIRN: 48,
                        velocity: 127,
                    },
                    {
                        id: 'f3afaa73-542d-4e26-ae4c-dbddabe3e470',
                        startIRN: 768,
                        key: 79,
                        lengthIRN: 48,
                        velocity: 127,
                    },
                    {
                        id: 'fb257529-b9b7-497c-9a2b-090476556d21',
                        startIRN: 816,
                        key: 72,
                        lengthIRN: 48,
                        velocity: 127,
                    },
                    {
                        id: 'd45f436d-a3ea-42f0-bd33-8f34bbee7990',
                        startIRN: 816,
                        key: 79,
                        lengthIRN: 48,
                        velocity: 127,
                    },
                    {
                        id: 'adf122a3-6d26-41ac-b62a-135603101bb2',
                        startIRN: 864,
                        key: 72,
                        lengthIRN: 48,
                        velocity: 127,
                    },
                    {
                        id: '3ffc97a5-2cea-4a3b-b0b4-e38fe79b0a32',
                        startIRN: 864,
                        key: 79,
                        lengthIRN: 48,
                        velocity: 127,
                    },
                    {
                        id: '1a47ef35-5a08-4575-b160-969ab53b9244',
                        startIRN: 912,
                        key: 72,
                        lengthIRN: 48,
                        velocity: 127,
                    },
                    {
                        id: '102cf464-4d5f-4136-9bf3-5282739db7f8',
                        startIRN: 912,
                        key: 79,
                        lengthIRN: 48,
                        velocity: 127,
                    },
                    {
                        id: '676f64bb-7f2d-4a1b-8c4e-56c01ada715e',
                        startIRN: 960,
                        key: 72,
                        lengthIRN: 48,
                        velocity: 127,
                    },
                    {
                        id: 'b6b615b0-0316-4606-aba3-9c0b1a7cee4d',
                        startIRN: 960,
                        key: 79,
                        lengthIRN: 48,
                        velocity: 127,
                    },
                    {
                        id: '3a980c9a-cf6c-4d2c-b3b9-161c18f019fe',
                        startIRN: 1152,
                        key: 67,
                        lengthIRN: 48,
                        velocity: 127,
                    },
                    {
                        id: '5929301e-e8e2-457f-8105-bede9cc5be3c',
                        startIRN: 1152,
                        key: 74,
                        lengthIRN: 48,
                        velocity: 127,
                    },
                    {
                        id: '9396c2b1-c798-4dcf-a380-60735890882d',
                        startIRN: 1200,
                        key: 67,
                        lengthIRN: 48,
                        velocity: 127,
                    },
                    {
                        id: '1e1c1784-99b7-4e4b-9f41-56e807b0b40b',
                        startIRN: 1200,
                        key: 74,
                        lengthIRN: 48,
                        velocity: 127,
                    },
                    {
                        id: 'e6097543-30d9-4cc1-93ac-9eb989383698',
                        startIRN: 1248,
                        key: 67,
                        lengthIRN: 48,
                        velocity: 127,
                    },
                    {
                        id: 'd4f1139d-60bd-4bae-ab17-6304dc324028',
                        startIRN: 1248,
                        key: 74,
                        lengthIRN: 48,
                        velocity: 127,
                    },
                    {
                        id: 'fa79c90b-05b7-457c-8890-36c22c553d29',
                        startIRN: 1296,
                        key: 67,
                        lengthIRN: 48,
                        velocity: 127,
                    },
                    {
                        id: '3f68db78-d17a-467a-849f-17a85c4b60d8',
                        startIRN: 1296,
                        key: 74,
                        lengthIRN: 48,
                        velocity: 127,
                    },
                    {
                        id: 'cc20eb18-0e3a-40e0-abad-95607537e0c2',
                        startIRN: 1344,
                        key: 67,
                        lengthIRN: 48,
                        velocity: 127,
                    },
                    {
                        id: '70a1c1c8-c6d3-48c7-8148-8a3ed480fe7d',
                        startIRN: 1344,
                        key: 74,
                        lengthIRN: 48,
                        velocity: 127,
                    },
                ],
                color: Color.RED,
                type: PatternType.PianoRollPattern,
            },
            '1c03d821-b9df-486b-898b-d07967df8a25': {
                id: '1c03d821-b9df-486b-898b-d07967df8a25',
                lastResolution: 4,
                lengthIRN: 1536,
                notes: [
                    {
                        id: '8df65646-67f6-45b6-a6bd-8533d4a6e23b',
                        key: 36,
                        startIRN: 0,
                        velocity: 127,
                        lengthIRN: 96,
                    },
                    {
                        id: 'dc93afb5-d05b-4101-b275-c5499be3c94c',
                        key: 38,
                        startIRN: 192,
                        velocity: 127,
                        lengthIRN: 96,
                    },
                    {
                        id: '8ec9bb3e-0387-4f6f-81fc-10bfff7bdabb',
                        key: 36,
                        startIRN: 384,
                        velocity: 127,
                        lengthIRN: 96,
                    },
                    {
                        id: '15eb7f0a-9672-4886-a3ec-740e988d2200',
                        key: 38,
                        startIRN: 576,
                        velocity: 127,
                        lengthIRN: 96,
                    },
                    {
                        id: '1f2ecbea-3369-4aa4-8248-23665b03d363',
                        key: 36,
                        startIRN: 768,
                        velocity: 127,
                        lengthIRN: 96,
                    },
                    {
                        id: '0fdcfad4-08a6-43f7-a541-cf48eb7a98bc',
                        key: 38,
                        startIRN: 960,
                        velocity: 127,
                        lengthIRN: 96,
                    },
                    {
                        id: 'fdf395d3-37ad-40b3-94a8-80577272da0b',
                        key: 36,
                        startIRN: 1152,
                        velocity: 127,
                        lengthIRN: 96,
                    },
                    {
                        id: '87944edd-f700-4310-a817-c08cccc841d9',
                        key: 38,
                        startIRN: 1344,
                        velocity: 127,
                        lengthIRN: 96,
                    },
                ],
                color: Color.MAGENTA,
                type: PatternType.SequencerPattern,
            },
            '93b0c020-1a26-4f15-a65a-7b3b45b73de5': {
                id: '93b0c020-1a26-4f15-a65a-7b3b45b73de5',
                lastResolution: 4,
                lengthIRN: 1536,
                notes: [
                    {
                        id: '8df65646-67f6-45b6-a6bd-8533d4a6e23b',
                        key: 36,
                        startIRN: 0,
                        velocity: 127,
                        lengthIRN: 96,
                    },
                    {
                        id: 'dc93afb5-d05b-4101-b275-c5499be3c94c',
                        key: 38,
                        startIRN: 192,
                        velocity: 127,
                        lengthIRN: 96,
                    },
                    {
                        id: '8ec9bb3e-0387-4f6f-81fc-10bfff7bdabb',
                        key: 36,
                        startIRN: 384,
                        velocity: 127,
                        lengthIRN: 96,
                    },
                    {
                        id: '15eb7f0a-9672-4886-a3ec-740e988d2200',
                        key: 38,
                        startIRN: 576,
                        velocity: 127,
                        lengthIRN: 96,
                    },
                    {
                        id: '1f2ecbea-3369-4aa4-8248-23665b03d363',
                        key: 36,
                        startIRN: 768,
                        velocity: 127,
                        lengthIRN: 96,
                    },
                    {
                        id: '0fdcfad4-08a6-43f7-a541-cf48eb7a98bc',
                        key: 38,
                        startIRN: 960,
                        velocity: 127,
                        lengthIRN: 96,
                    },
                    {
                        id: 'fdf395d3-37ad-40b3-94a8-80577272da0b',
                        key: 36,
                        startIRN: 1152,
                        velocity: 127,
                        lengthIRN: 96,
                    },
                    {
                        id: '87944edd-f700-4310-a817-c08cccc841d9',
                        key: 38,
                        startIRN: 1344,
                        velocity: 127,
                        lengthIRN: 96,
                    },
                ],
                color: Color.BLUE,
                type: PatternType.SequencerPattern,
            },
            '8462bca7-25c8-479b-9441-404908c6dea1': {
                id: '8462bca7-25c8-479b-9441-404908c6dea1',
                lastResolution: 8,
                lengthIRN: 1536,
                notes: [
                    {
                        id: '8361f2b7-8a2d-47a8-af4e-2621189f0682',
                        startIRN: 0,
                        key: 69,
                        lengthIRN: 48,
                        velocity: 127,
                    },
                    {
                        id: '10ef6764-74b6-41ba-a7f2-d65effdb81c7',
                        startIRN: 0,
                        key: 72,
                        lengthIRN: 48,
                        velocity: 127,
                    },
                    {
                        id: 'f4895fcb-f344-4cf4-852a-d900dc5d529a',
                        startIRN: 0,
                        key: 76,
                        lengthIRN: 48,
                        velocity: 127,
                    },
                    {
                        id: 'ec148f53-695a-41ff-87f8-7c096c786a06',
                        startIRN: 48,
                        key: 69,
                        lengthIRN: 48,
                        velocity: 127,
                    },
                    {
                        id: '6d4a8fb2-0ffc-4d4d-a48d-6ef197636f79',
                        startIRN: 48,
                        key: 72,
                        lengthIRN: 48,
                        velocity: 127,
                    },
                    {
                        id: '0c67c5f3-a46a-4af3-9abe-edd9f15cab40',
                        startIRN: 48,
                        key: 76,
                        lengthIRN: 48,
                        velocity: 127,
                    },
                    {
                        id: 'c3894e13-9c28-4391-8dc8-e61fa1381ad5',
                        startIRN: 96,
                        key: 69,
                        lengthIRN: 48,
                        velocity: 127,
                    },
                    {
                        id: 'a3acba14-1236-44cd-a3c7-816ed79fd741',
                        startIRN: 96,
                        key: 72,
                        lengthIRN: 48,
                        velocity: 127,
                    },
                    {
                        id: 'f26b0d41-7602-4f16-bbaa-78837fff5eaa',
                        startIRN: 96,
                        key: 76,
                        lengthIRN: 48,
                        velocity: 127,
                    },
                    {
                        id: '42de240f-7031-4c1d-b1f3-30679958fa07',
                        startIRN: 144,
                        key: 69,
                        lengthIRN: 48,
                        velocity: 127,
                    },
                    {
                        id: '84b0a8c0-0c47-4fb0-919d-9499c744897d',
                        startIRN: 144,
                        key: 72,
                        lengthIRN: 48,
                        velocity: 127,
                    },
                    {
                        id: '1c61bae4-a43b-44b6-b79d-7c94c456429e',
                        startIRN: 144,
                        key: 76,
                        lengthIRN: 48,
                        velocity: 127,
                    },
                    {
                        id: '9f0a143f-4a77-4169-ab9a-4dbbc3528af9',
                        startIRN: 192,
                        key: 69,
                        lengthIRN: 48,
                        velocity: 127,
                    },
                    {
                        id: 'de4f0966-0786-48c5-8907-331acc6aa83e',
                        startIRN: 192,
                        key: 72,
                        lengthIRN: 48,
                        velocity: 127,
                    },
                    {
                        id: 'de0eb9ba-b540-464a-95bd-80a4f0ebdb9a',
                        startIRN: 192,
                        key: 76,
                        lengthIRN: 48,
                        velocity: 127,
                    },
                    {
                        id: '98fc7d47-0ab9-4678-98ee-ffdaa02a8fd0',
                        startIRN: 384,
                        key: 74,
                        lengthIRN: 48,
                        velocity: 127,
                    },
                    {
                        id: '9f6228d7-65e8-4b21-a00b-1702afd9d57d',
                        startIRN: 384,
                        key: 77,
                        lengthIRN: 48,
                        velocity: 127,
                    },
                    {
                        id: '5033e0ef-ea01-4187-950e-c856849fc492',
                        startIRN: 384,
                        key: 81,
                        lengthIRN: 48,
                        velocity: 127,
                    },
                    {
                        id: 'd2ebd4ac-d73b-468d-a40c-ec27c1b19355',
                        startIRN: 432,
                        key: 74,
                        lengthIRN: 48,
                        velocity: 127,
                    },
                    {
                        id: 'c280b795-2f11-40e9-bbe4-d8f2a2f70a04',
                        startIRN: 432,
                        key: 77,
                        lengthIRN: 48,
                        velocity: 127,
                    },
                    {
                        id: 'b84c6511-57b5-4926-a248-21a6ca4b7c7e',
                        startIRN: 432,
                        key: 81,
                        lengthIRN: 48,
                        velocity: 127,
                    },
                    {
                        id: '4a045136-c18c-46e4-a733-bd6e7ed48bab',
                        startIRN: 480,
                        key: 74,
                        lengthIRN: 48,
                        velocity: 127,
                    },
                    {
                        id: '9d3bd6cb-9cce-4e8e-a10d-398952d876bd',
                        startIRN: 480,
                        key: 77,
                        lengthIRN: 48,
                        velocity: 127,
                    },
                    {
                        id: '4dc52ec4-6983-4072-a3f5-d24963e19dae',
                        startIRN: 480,
                        key: 81,
                        lengthIRN: 48,
                        velocity: 127,
                    },
                    {
                        id: '6ca11a76-5919-4744-810b-e868e7ca1555',
                        startIRN: 528,
                        key: 74,
                        lengthIRN: 48,
                        velocity: 127,
                    },
                    {
                        id: '0ac09579-79a5-49bf-811f-24163ba397b6',
                        startIRN: 528,
                        key: 77,
                        lengthIRN: 48,
                        velocity: 127,
                    },
                    {
                        id: '1e21154a-1830-4e9a-9530-6c6d350a7f76',
                        startIRN: 528,
                        key: 81,
                        lengthIRN: 48,
                        velocity: 127,
                    },
                    {
                        id: 'a6257876-e194-4a58-b91c-5215d632187f',
                        startIRN: 576,
                        key: 74,
                        lengthIRN: 48,
                        velocity: 127,
                    },
                    {
                        id: 'e0a2b164-8cf6-4e40-800c-f90d833617e0',
                        startIRN: 576,
                        key: 77,
                        lengthIRN: 48,
                        velocity: 127,
                    },
                    {
                        id: 'a94621dc-8b44-418a-9d26-29bb26fb5f16',
                        startIRN: 576,
                        key: 81,
                        lengthIRN: 48,
                        velocity: 127,
                    },
                    {
                        id: 'f41663c1-307d-499e-aa67-ae5688372ab8',
                        startIRN: 768,
                        key: 72,
                        lengthIRN: 48,
                        velocity: 127,
                    },
                    {
                        id: 'f3afaa73-542d-4e26-ae4c-dbddabe3e470',
                        startIRN: 768,
                        key: 79,
                        lengthIRN: 48,
                        velocity: 127,
                    },
                    {
                        id: 'fb257529-b9b7-497c-9a2b-090476556d21',
                        startIRN: 816,
                        key: 72,
                        lengthIRN: 48,
                        velocity: 127,
                    },
                    {
                        id: 'd45f436d-a3ea-42f0-bd33-8f34bbee7990',
                        startIRN: 816,
                        key: 79,
                        lengthIRN: 48,
                        velocity: 127,
                    },
                    {
                        id: 'adf122a3-6d26-41ac-b62a-135603101bb2',
                        startIRN: 864,
                        key: 72,
                        lengthIRN: 48,
                        velocity: 127,
                    },
                    {
                        id: '3ffc97a5-2cea-4a3b-b0b4-e38fe79b0a32',
                        startIRN: 864,
                        key: 79,
                        lengthIRN: 48,
                        velocity: 127,
                    },
                    {
                        id: '1a47ef35-5a08-4575-b160-969ab53b9244',
                        startIRN: 912,
                        key: 72,
                        lengthIRN: 48,
                        velocity: 127,
                    },
                    {
                        id: '102cf464-4d5f-4136-9bf3-5282739db7f8',
                        startIRN: 912,
                        key: 79,
                        lengthIRN: 48,
                        velocity: 127,
                    },
                    {
                        id: '676f64bb-7f2d-4a1b-8c4e-56c01ada715e',
                        startIRN: 960,
                        key: 72,
                        lengthIRN: 48,
                        velocity: 127,
                    },
                    {
                        id: 'b6b615b0-0316-4606-aba3-9c0b1a7cee4d',
                        startIRN: 960,
                        key: 79,
                        lengthIRN: 48,
                        velocity: 127,
                    },
                    {
                        id: '3a980c9a-cf6c-4d2c-b3b9-161c18f019fe',
                        startIRN: 1152,
                        key: 67,
                        lengthIRN: 48,
                        velocity: 127,
                    },
                    {
                        id: '5929301e-e8e2-457f-8105-bede9cc5be3c',
                        startIRN: 1152,
                        key: 74,
                        lengthIRN: 48,
                        velocity: 127,
                    },
                    {
                        id: '9396c2b1-c798-4dcf-a380-60735890882d',
                        startIRN: 1200,
                        key: 67,
                        lengthIRN: 48,
                        velocity: 127,
                    },
                    {
                        id: '1e1c1784-99b7-4e4b-9f41-56e807b0b40b',
                        startIRN: 1200,
                        key: 74,
                        lengthIRN: 48,
                        velocity: 127,
                    },
                    {
                        id: 'e6097543-30d9-4cc1-93ac-9eb989383698',
                        startIRN: 1248,
                        key: 67,
                        lengthIRN: 48,
                        velocity: 127,
                    },
                    {
                        id: 'd4f1139d-60bd-4bae-ab17-6304dc324028',
                        startIRN: 1248,
                        key: 74,
                        lengthIRN: 48,
                        velocity: 127,
                    },
                    {
                        id: 'fa79c90b-05b7-457c-8890-36c22c553d29',
                        startIRN: 1296,
                        key: 67,
                        lengthIRN: 48,
                        velocity: 127,
                    },
                    {
                        id: '3f68db78-d17a-467a-849f-17a85c4b60d8',
                        startIRN: 1296,
                        key: 74,
                        lengthIRN: 48,
                        velocity: 127,
                    },
                    {
                        id: 'cc20eb18-0e3a-40e0-abad-95607537e0c2',
                        startIRN: 1344,
                        key: 67,
                        lengthIRN: 48,
                        velocity: 127,
                    },
                    {
                        id: '70a1c1c8-c6d3-48c7-8148-8a3ed480fe7d',
                        startIRN: 1344,
                        key: 74,
                        lengthIRN: 48,
                        velocity: 127,
                    },
                ],
                color: Color.APRICOT,
                type: PatternType.PianoRollPattern,
            },
            '30c1946d-12cf-4750-9a8c-f6eae09eb0a8': {
                id: '30c1946d-12cf-4750-9a8c-f6eae09eb0a8',
                lastResolution: 16,
                lengthIRN: 1536,
                notes: [
                    {
                        id: 'ef417ae2-6bf7-4f70-b487-b5ad2354378a',
                        startIRN: 0,
                        key: 72,
                        lengthIRN: 12,
                        velocity: 127,
                    },
                    {
                        id: '2e5d69f9-b15b-400a-92b8-7e0d12cf3414',
                        startIRN: 12,
                        key: 79,
                        lengthIRN: 12,
                        velocity: 127,
                    },
                    {
                        id: '96403448-bf0b-46d1-b073-989d2c3c0b1b',
                        startIRN: 24,
                        key: 74,
                        lengthIRN: 12,
                        velocity: 127,
                    },
                    {
                        id: '99be6392-971f-42ad-8700-8127b0ab2bfe',
                        startIRN: 36,
                        key: 84,
                        lengthIRN: 12,
                        velocity: 127,
                    },
                    {
                        id: 'ab80bd56-b233-4b83-853d-c710087594ea',
                        startIRN: 48,
                        key: 79,
                        lengthIRN: 12,
                        velocity: 127,
                    },
                    {
                        id: 'a488d2f1-0056-4514-9913-f62c53d2a250',
                        startIRN: 60,
                        key: 86,
                        lengthIRN: 12,
                        velocity: 127,
                    },
                    {
                        id: 'a182174a-a0d4-4095-b641-1fc817c0389b',
                        startIRN: 72,
                        key: 81,
                        lengthIRN: 12,
                        velocity: 127,
                    },
                    {
                        id: '9b93605f-2a7b-401d-b49a-be955c24c54a',
                        startIRN: 84,
                        key: 88,
                        lengthIRN: 12,
                        velocity: 127,
                    },
                    {
                        id: 'f734ebf3-c250-49e0-a3db-fc14b03fe0cd',
                        startIRN: 96,
                        key: 84,
                        lengthIRN: 12,
                        velocity: 127,
                    },
                    {
                        id: '516bc3d2-5853-47a4-9200-3b2b709faf8b',
                        startIRN: 108,
                        key: 79,
                        lengthIRN: 12,
                        velocity: 127,
                    },
                    {
                        id: '9e618b15-b55f-46d8-9592-cd876e634bb3',
                        startIRN: 120,
                        key: 84,
                        lengthIRN: 12,
                        velocity: 127,
                    },
                    {
                        id: '1e6e8d91-30dd-4fbd-9694-40806b9c50b8',
                        startIRN: 132,
                        key: 76,
                        lengthIRN: 12,
                        velocity: 127,
                    },
                    {
                        id: 'ceda683b-e22c-437c-8376-86a1de3d9f55',
                        startIRN: 144,
                        key: 84,
                        lengthIRN: 12,
                        velocity: 127,
                    },
                    {
                        id: 'cc64023f-dfae-473b-ad99-1891f49da4a4',
                        startIRN: 156,
                        key: 74,
                        lengthIRN: 12,
                        velocity: 127,
                    },
                    {
                        id: '8cae63d8-3c46-4ba9-95e7-22269361930a',
                        startIRN: 168,
                        key: 84,
                        lengthIRN: 12,
                        velocity: 127,
                    },
                    {
                        id: 'a045dfa8-ba3b-4f5d-baf9-5f05a3c0f187',
                        startIRN: 180,
                        key: 79,
                        lengthIRN: 12,
                        velocity: 127,
                    },
                    {
                        id: '48bca331-8a19-4188-8f45-0e2f70bca05e',
                        startIRN: 192,
                        key: 84,
                        lengthIRN: 12,
                        velocity: 127,
                    },
                    {
                        id: '427f7519-15fa-4ff2-b5ab-744c101c3c0d',
                        startIRN: 204,
                        key: 76,
                        lengthIRN: 12,
                        velocity: 127,
                    },
                    {
                        id: '579dbe3e-c443-464f-8028-df72aaa7f315',
                        startIRN: 216,
                        key: 84,
                        lengthIRN: 12,
                        velocity: 127,
                    },
                    {
                        id: '0aa0a7d6-0719-43be-a053-aec144c34d19',
                        startIRN: 228,
                        key: 74,
                        lengthIRN: 12,
                        velocity: 127,
                    },
                    {
                        id: '6031765e-87c5-4c82-a70e-3d9e16101b4e',
                        startIRN: 240,
                        key: 79,
                        lengthIRN: 12,
                        velocity: 127,
                    },
                    {
                        id: 'd7e8a6dc-26d3-4c6c-8c77-b44979bbc9c0',
                        startIRN: 252,
                        key: 81,
                        lengthIRN: 12,
                        velocity: 127,
                    },
                    {
                        id: 'f5ecbcfb-29f4-4e5e-910f-9cd24f31e5e9',
                        startIRN: 264,
                        key: 84,
                        lengthIRN: 12,
                        velocity: 127,
                    },
                    {
                        id: '967e52bb-9aa7-4a00-8d76-5bd5b7763d77',
                        startIRN: 276,
                        key: 81,
                        lengthIRN: 12,
                        velocity: 127,
                    },
                    {
                        id: '354b3e8d-8a79-44e9-8618-45bc25b29986',
                        startIRN: 288,
                        key: 79,
                        lengthIRN: 12,
                        velocity: 127,
                    },
                    {
                        id: 'd48e435e-8fc0-457d-9985-db88940a8263',
                        startIRN: 300,
                        key: 81,
                        lengthIRN: 12,
                        velocity: 127,
                    },
                    {
                        id: '39dc02bf-b441-4994-9333-ab85620110bd',
                        startIRN: 312,
                        key: 84,
                        lengthIRN: 12,
                        velocity: 127,
                    },
                    {
                        id: 'debae442-59ac-434f-ab32-a5d2142b8137',
                        startIRN: 324,
                        key: 81,
                        lengthIRN: 12,
                        velocity: 127,
                    },
                    {
                        id: '54bb7b12-ff8f-4ae1-89a2-3b8d20fafbbd',
                        startIRN: 336,
                        key: 79,
                        lengthIRN: 12,
                        velocity: 127,
                    },
                    {
                        id: '2eeefe5e-ab6b-4f95-929c-0bc3d4945b6c',
                        startIRN: 348,
                        key: 81,
                        lengthIRN: 12,
                        velocity: 127,
                    },
                    {
                        id: '2edcccbb-d14f-4f49-807b-5d41f002703c',
                        startIRN: 360,
                        key: 84,
                        lengthIRN: 12,
                        velocity: 127,
                    },
                    {
                        id: '3777b97b-d0e8-4589-9bb2-7e58cf35464c',
                        startIRN: 372,
                        key: 81,
                        lengthIRN: 12,
                        velocity: 127,
                    },
                    {
                        id: 'd5a99a4e-317e-4e12-ba6c-a47a0b528b31',
                        startIRN: 384,
                        key: 79,
                        lengthIRN: 12,
                        velocity: 127,
                    },
                    {
                        id: 'b7590d2a-9bfa-47e2-a2d4-b06c77f30919',
                        startIRN: 396,
                        key: 76,
                        lengthIRN: 12,
                        velocity: 127,
                    },
                    {
                        id: 'da999183-892c-49f7-9f6b-3caba9b2a457',
                        startIRN: 408,
                        key: 74,
                        lengthIRN: 12,
                        velocity: 127,
                    },
                    {
                        id: '688de346-b1e0-4f5e-8e77-2d883ee2a440',
                        startIRN: 420,
                        key: 72,
                        lengthIRN: 12,
                        velocity: 127,
                    },
                    {
                        id: '5843618f-620c-4a41-b490-e0cbd6ef2676',
                        startIRN: 432,
                        key: 69,
                        lengthIRN: 12,
                        velocity: 127,
                    },
                    {
                        id: 'ab448dc0-3096-4e2d-a635-ea73b2c394ec',
                        startIRN: 444,
                        key: 67,
                        lengthIRN: 12,
                        velocity: 127,
                    },
                    {
                        id: 'e642e8cd-f83f-48d8-978d-52bfd86ec1b1',
                        startIRN: 456,
                        key: 69,
                        lengthIRN: 12,
                        velocity: 127,
                    },
                    {
                        id: '62a82763-2103-44bc-9456-2704bad59b66',
                        startIRN: 468,
                        key: 72,
                        lengthIRN: 12,
                        velocity: 127,
                    },
                    {
                        id: '171c16ae-69a6-4793-8287-a7ceb3231621',
                        startIRN: 480,
                        key: 74,
                        lengthIRN: 12,
                        velocity: 127,
                    },
                    {
                        id: '5d46792b-4590-4c02-8c0a-a66946e04b39',
                        startIRN: 492,
                        key: 76,
                        lengthIRN: 12,
                        velocity: 127,
                    },
                    {
                        id: '793876b5-b20a-43c2-98db-d378ab1a5e36',
                        startIRN: 504,
                        key: 79,
                        lengthIRN: 12,
                        velocity: 127,
                    },
                    {
                        id: '529d984e-12ad-43f3-996a-21493dd69092',
                        startIRN: 516,
                        key: 81,
                        lengthIRN: 12,
                        velocity: 127,
                    },
                    {
                        id: '593c936b-41ae-4901-a4e3-b06429bb71ea',
                        startIRN: 528,
                        key: 84,
                        lengthIRN: 12,
                        velocity: 127,
                    },
                    {
                        id: '939d42a8-ad72-48de-ad3d-b3643ded43be',
                        startIRN: 540,
                        key: 86,
                        lengthIRN: 12,
                        velocity: 127,
                    },
                    {
                        id: '17795f73-21df-4ec4-afe2-6ca3a870308f',
                        startIRN: 552,
                        key: 88,
                        lengthIRN: 12,
                        velocity: 127,
                    },
                    {
                        id: '6ebfce65-66b0-43a7-97f0-afd24b9d9472',
                        startIRN: 564,
                        key: 86,
                        lengthIRN: 12,
                        velocity: 127,
                    },
                    {
                        id: '8257635e-6ed9-4d5d-97f0-b6fed9847a0b',
                        startIRN: 576,
                        key: 84,
                        lengthIRN: 12,
                        velocity: 127,
                    },
                    {
                        id: '03a12c34-9939-4e87-92c4-a7a31b075631',
                        startIRN: 588,
                        key: 81,
                        lengthIRN: 12,
                        velocity: 127,
                    },
                    {
                        id: '5e5befcf-06ac-4f96-b24d-be8372a44d4a',
                        startIRN: 600,
                        key: 79,
                        lengthIRN: 12,
                        velocity: 127,
                    },
                    {
                        id: 'b07fb2af-4c57-4c9a-83ac-75133da78393',
                        startIRN: 612,
                        key: 76,
                        lengthIRN: 12,
                        velocity: 127,
                    },
                    {
                        id: 'b0ba70ff-ee87-477f-9e4b-948913497936',
                        startIRN: 624,
                        key: 74,
                        lengthIRN: 12,
                        velocity: 127,
                    },
                    {
                        id: '349c2151-6899-421b-8152-b1e66ad8139d',
                        startIRN: 636,
                        key: 72,
                        lengthIRN: 12,
                        velocity: 127,
                    },
                    {
                        id: '3c3943fd-1aa5-4b9a-bbf2-14889269fe64',
                        startIRN: 648,
                        key: 69,
                        lengthIRN: 12,
                        velocity: 127,
                    },
                    {
                        id: '803e7f2d-8230-4d20-bb81-2865ce08a89e',
                        startIRN: 660,
                        key: 67,
                        lengthIRN: 12,
                        velocity: 127,
                    },
                    {
                        id: '1bbd6606-0e24-4f7e-b937-30b1c1722beb',
                        startIRN: 672,
                        key: 69,
                        lengthIRN: 12,
                        velocity: 127,
                    },
                    {
                        id: 'b6914f84-f05b-4813-8d4e-0bd9399f6328',
                        startIRN: 684,
                        key: 72,
                        lengthIRN: 12,
                        velocity: 127,
                    },
                    {
                        id: '2f087694-9dbd-43e3-962d-d13414116a7c',
                        startIRN: 696,
                        key: 74,
                        lengthIRN: 12,
                        velocity: 127,
                    },
                    {
                        id: '209f7660-68c5-41aa-a22f-170d108a26a0',
                        startIRN: 708,
                        key: 76,
                        lengthIRN: 12,
                        velocity: 127,
                    },
                    {
                        id: 'cb5ba2c1-4d33-4454-ae85-1ce6ba2857fc',
                        startIRN: 720,
                        key: 74,
                        lengthIRN: 12,
                        velocity: 127,
                    },
                    {
                        id: '1d84c062-eeb8-41fd-aacc-31644f622e40',
                        startIRN: 732,
                        key: 72,
                        lengthIRN: 12,
                        velocity: 127,
                    },
                    {
                        id: 'bf12a765-7fc3-4a3f-9289-58a2d1450a8d',
                        startIRN: 744,
                        key: 74,
                        lengthIRN: 12,
                        velocity: 127,
                    },
                    {
                        id: 'da59dc9d-be26-4150-bec0-70430b054875',
                        startIRN: 756,
                        key: 76,
                        lengthIRN: 12,
                        velocity: 127,
                    },
                    {
                        id: '941ab219-0058-4bdb-813d-4e5baea286a3',
                        startIRN: 768,
                        key: 74,
                        lengthIRN: 12,
                        velocity: 127,
                    },
                    {
                        id: 'ae083df3-d524-4dd3-8ab1-0fb1f771415c',
                        startIRN: 780,
                        key: 79,
                        lengthIRN: 12,
                        velocity: 127,
                    },
                    {
                        id: '2cfd56ab-74e2-404a-a4b4-9bf4e27b35f1',
                        startIRN: 792,
                        key: 74,
                        lengthIRN: 12,
                        velocity: 127,
                    },
                    {
                        id: '88e3ef29-ef50-4c9e-9d3b-3a10eb80238a',
                        startIRN: 804,
                        key: 81,
                        lengthIRN: 12,
                        velocity: 127,
                    },
                    {
                        id: '8caf6733-1825-4b88-a720-8b671505d644',
                        startIRN: 816,
                        key: 74,
                        lengthIRN: 12,
                        velocity: 127,
                    },
                    {
                        id: '37196cc0-4f1b-4338-9e6d-c51def1fb978',
                        startIRN: 828,
                        key: 84,
                        lengthIRN: 12,
                        velocity: 127,
                    },
                    {
                        id: '9f0762f6-3278-4d09-a92e-f4935c99bfea',
                        startIRN: 840,
                        key: 74,
                        lengthIRN: 12,
                        velocity: 127,
                    },
                    {
                        id: 'e8ebb427-09f9-42b4-8b18-f32c66add482',
                        startIRN: 864,
                        key: 74,
                        lengthIRN: 12,
                        velocity: 127,
                    },
                    {
                        id: '83102026-5b07-47b4-b9ee-8bd8c472a719',
                        startIRN: 852,
                        key: 81,
                        lengthIRN: 12,
                        velocity: 127,
                    },
                    {
                        id: 'a63d8c75-e2b8-44e6-9390-f7e5ac27d0c3',
                        startIRN: 876,
                        key: 79,
                        lengthIRN: 12,
                        velocity: 127,
                    },
                    {
                        id: '931a114f-098b-4ea7-a9ec-e2f22a5a2c01',
                        startIRN: 888,
                        key: 74,
                        lengthIRN: 12,
                        velocity: 127,
                    },
                    {
                        id: '41b47299-1305-484f-9135-96dbae3d8e80',
                        startIRN: 900,
                        key: 79,
                        lengthIRN: 12,
                        velocity: 127,
                    },
                    {
                        id: 'e3762043-940e-4a3c-bfe1-14d31c64a431',
                        startIRN: 924,
                        key: 81,
                        lengthIRN: 12,
                        velocity: 127,
                    },
                    {
                        id: 'e713810c-1cb1-4536-b5e4-5c274bd445fa',
                        startIRN: 948,
                        key: 84,
                        lengthIRN: 12,
                        velocity: 127,
                    },
                    {
                        id: 'b1cedf39-2f4f-47b9-84d6-d7bd5d2466e1',
                        startIRN: 912,
                        key: 74,
                        lengthIRN: 12,
                        velocity: 127,
                    },
                    {
                        id: 'd5c09fe8-5c71-48de-a977-c4fc0cb4a543',
                        startIRN: 936,
                        key: 74,
                        lengthIRN: 12,
                        velocity: 127,
                    },
                    {
                        id: '04561fb6-0c53-43af-953a-dfdba42e456a',
                        startIRN: 960,
                        key: 74,
                        lengthIRN: 12,
                        velocity: 127,
                    },
                    {
                        id: '9c0109cb-13de-4849-a7d3-96f9684a2d00',
                        startIRN: 972,
                        key: 81,
                        lengthIRN: 12,
                        velocity: 127,
                    },
                    {
                        id: '9dcbbcba-9c22-48f8-8365-b5ba455b3878',
                        startIRN: 984,
                        key: 74,
                        lengthIRN: 12,
                        velocity: 127,
                    },
                    {
                        id: 'cf19e72b-a273-4f6b-9fa3-757fac7edbdd',
                        startIRN: 996,
                        key: 79,
                        lengthIRN: 12,
                        velocity: 127,
                    },
                    {
                        id: '9f8e2166-1600-4668-ac6b-5bac4a17ce3a',
                        startIRN: 1008,
                        key: 74,
                        lengthIRN: 12,
                        velocity: 127,
                    },
                    {
                        id: '6181856b-6eba-45d0-aaf7-6420b991e903',
                        startIRN: 1020,
                        key: 76,
                        lengthIRN: 12,
                        velocity: 127,
                    },
                    {
                        id: 'e26bce8a-cb2a-489f-9103-64e96fb6d21d',
                        startIRN: 1032,
                        key: 74,
                        lengthIRN: 12,
                        velocity: 127,
                    },
                    {
                        id: '7fa650b1-3c38-4cf2-8171-90088e4733fe',
                        startIRN: 1044,
                        key: 72,
                        lengthIRN: 12,
                        velocity: 127,
                    },
                    {
                        id: 'ce49f19a-4ba5-46ab-bb2e-823748c1f226',
                        startIRN: 1056,
                        key: 74,
                        lengthIRN: 12,
                        velocity: 127,
                    },
                    {
                        id: 'c03a95ef-c9d7-499f-be93-883abaac5d1f',
                        startIRN: 1068,
                        key: 76,
                        lengthIRN: 12,
                        velocity: 127,
                    },
                    {
                        id: 'aa9e366c-1187-485a-a05c-620a4d897d31',
                        startIRN: 1080,
                        key: 74,
                        lengthIRN: 12,
                        velocity: 127,
                    },
                    {
                        id: '4789b966-eaea-4bec-ba7b-1fde20d154ea',
                        startIRN: 1092,
                        key: 72,
                        lengthIRN: 12,
                        velocity: 127,
                    },
                    {
                        id: '8c725312-2c44-4d62-92a6-1699721c1687',
                        startIRN: 1104,
                        key: 69,
                        lengthIRN: 12,
                        velocity: 127,
                    },
                    {
                        id: '3ac18070-5c96-4e9c-8e8d-de71a4a6617d',
                        startIRN: 1116,
                        key: 67,
                        lengthIRN: 12,
                        velocity: 127,
                    },
                    {
                        id: '17b9c0c2-df41-41aa-99a4-7ee60570af1d',
                        startIRN: 1128,
                        key: 64,
                        lengthIRN: 12,
                        velocity: 127,
                    },
                    {
                        id: '98dad758-4c71-4ebf-9e0f-bd3859e48e43',
                        startIRN: 1140,
                        key: 62,
                        lengthIRN: 12,
                        velocity: 127,
                    },
                    {
                        id: '6fcd978f-b449-4f04-a64f-28176a1ced7e',
                        startIRN: 1152,
                        key: 60,
                        lengthIRN: 24,
                        velocity: 127,
                    },
                    {
                        id: '9291df22-19bd-4191-9467-04c158797d6b',
                        startIRN: 1176,
                        key: 62,
                        lengthIRN: 24,
                        velocity: 127,
                    },
                    {
                        id: '8ca5fc87-4bd2-4c70-bef6-d110b388e0cf',
                        startIRN: 1200,
                        key: 64,
                        lengthIRN: 24,
                        velocity: 127,
                    },
                    {
                        id: '9d7d881b-50b4-4028-9533-4f840e24faa9',
                        startIRN: 1248,
                        key: 60,
                        lengthIRN: 24,
                        velocity: 127,
                    },
                    {
                        id: 'fae1dea1-23e0-4436-8ccf-8ac6f2ec16a9',
                        startIRN: 1272,
                        key: 62,
                        lengthIRN: 24,
                        velocity: 127,
                    },
                    {
                        id: '9acd3b07-9855-4e96-a6b6-5246814d1c44',
                        startIRN: 1296,
                        key: 64,
                        lengthIRN: 24,
                        velocity: 127,
                    },
                    {
                        id: 'e1c047af-987d-4c68-a9e6-eb810bbde2f3',
                        startIRN: 1224,
                        key: 67,
                        lengthIRN: 24,
                        velocity: 127,
                    },
                    {
                        id: 'c688bb78-bbda-4fd5-89a7-237f521c801c',
                        startIRN: 1320,
                        key: 67,
                        lengthIRN: 24,
                        velocity: 127,
                    },
                    {
                        id: '8e9302eb-9cab-4536-94a2-555c37b8c3f2',
                        startIRN: 1344,
                        key: 60,
                        lengthIRN: 24,
                        velocity: 127,
                    },
                    {
                        id: 'aa8af424-b69a-4612-8222-d0e34bd5d9d7',
                        startIRN: 1368,
                        key: 62,
                        lengthIRN: 24,
                        velocity: 127,
                    },
                    {
                        id: '03f7dfee-554a-4a8d-a93e-de7aed2d8f7b',
                        startIRN: 1392,
                        key: 64,
                        lengthIRN: 24,
                        velocity: 127,
                    },
                    {
                        id: '6586d0cf-fd43-4a74-b539-6a4e90f7caf0',
                        startIRN: 1416,
                        key: 67,
                        lengthIRN: 24,
                        velocity: 127,
                    },
                    {
                        id: '5ae94a2e-ecc5-4765-a803-49df9ad62ff8',
                        startIRN: 1440,
                        key: 69,
                        lengthIRN: 24,
                        velocity: 127,
                    },
                    {
                        id: 'a45d4bf2-3f1b-4f58-bdc8-812d67d822fa',
                        startIRN: 1464,
                        key: 67,
                        lengthIRN: 24,
                        velocity: 127,
                    },
                    {
                        id: '1a8ad667-e99b-4b47-a6eb-e4071fbfb87f',
                        startIRN: 1488,
                        key: 69,
                        lengthIRN: 24,
                        velocity: 127,
                    },
                    {
                        id: '8194b726-8c2e-4387-8c57-1fdf8533c921',
                        startIRN: 1512,
                        key: 67,
                        lengthIRN: 24,
                        velocity: 127,
                    },
                ],
                color: Color.CYAN,
                type: PatternType.PianoRollPattern,
            },
            'e09f69d5-f516-4784-94f7-3d29be2840ef': {
                id: 'e09f69d5-f516-4784-94f7-3d29be2840ef',
                lastResolution: 8,
                lengthIRN: 1536,
                notes: [
                    {
                        id: '8361f2b7-8a2d-47a8-af4e-2621189f0682',
                        startIRN: 0,
                        key: 69,
                        lengthIRN: 48,
                        velocity: 127,
                    },
                    {
                        id: '10ef6764-74b6-41ba-a7f2-d65effdb81c7',
                        startIRN: 0,
                        key: 72,
                        lengthIRN: 48,
                        velocity: 127,
                    },
                    {
                        id: 'f4895fcb-f344-4cf4-852a-d900dc5d529a',
                        startIRN: 0,
                        key: 76,
                        lengthIRN: 48,
                        velocity: 127,
                    },
                    {
                        id: 'ec148f53-695a-41ff-87f8-7c096c786a06',
                        startIRN: 48,
                        key: 69,
                        lengthIRN: 48,
                        velocity: 127,
                    },
                    {
                        id: '6d4a8fb2-0ffc-4d4d-a48d-6ef197636f79',
                        startIRN: 48,
                        key: 72,
                        lengthIRN: 48,
                        velocity: 127,
                    },
                    {
                        id: '0c67c5f3-a46a-4af3-9abe-edd9f15cab40',
                        startIRN: 48,
                        key: 76,
                        lengthIRN: 48,
                        velocity: 127,
                    },
                    {
                        id: 'c3894e13-9c28-4391-8dc8-e61fa1381ad5',
                        startIRN: 96,
                        key: 69,
                        lengthIRN: 48,
                        velocity: 127,
                    },
                    {
                        id: 'a3acba14-1236-44cd-a3c7-816ed79fd741',
                        startIRN: 96,
                        key: 72,
                        lengthIRN: 48,
                        velocity: 127,
                    },
                    {
                        id: 'f26b0d41-7602-4f16-bbaa-78837fff5eaa',
                        startIRN: 96,
                        key: 76,
                        lengthIRN: 48,
                        velocity: 127,
                    },
                    {
                        id: '42de240f-7031-4c1d-b1f3-30679958fa07',
                        startIRN: 144,
                        key: 69,
                        lengthIRN: 48,
                        velocity: 127,
                    },
                    {
                        id: '84b0a8c0-0c47-4fb0-919d-9499c744897d',
                        startIRN: 144,
                        key: 72,
                        lengthIRN: 48,
                        velocity: 127,
                    },
                    {
                        id: '1c61bae4-a43b-44b6-b79d-7c94c456429e',
                        startIRN: 144,
                        key: 76,
                        lengthIRN: 48,
                        velocity: 127,
                    },
                    {
                        id: '9f0a143f-4a77-4169-ab9a-4dbbc3528af9',
                        startIRN: 192,
                        key: 69,
                        lengthIRN: 48,
                        velocity: 127,
                    },
                    {
                        id: 'de4f0966-0786-48c5-8907-331acc6aa83e',
                        startIRN: 192,
                        key: 72,
                        lengthIRN: 48,
                        velocity: 127,
                    },
                    {
                        id: 'de0eb9ba-b540-464a-95bd-80a4f0ebdb9a',
                        startIRN: 192,
                        key: 76,
                        lengthIRN: 48,
                        velocity: 127,
                    },
                    {
                        id: '98fc7d47-0ab9-4678-98ee-ffdaa02a8fd0',
                        startIRN: 384,
                        key: 74,
                        lengthIRN: 48,
                        velocity: 127,
                    },
                    {
                        id: '9f6228d7-65e8-4b21-a00b-1702afd9d57d',
                        startIRN: 384,
                        key: 77,
                        lengthIRN: 48,
                        velocity: 127,
                    },
                    {
                        id: '5033e0ef-ea01-4187-950e-c856849fc492',
                        startIRN: 384,
                        key: 81,
                        lengthIRN: 48,
                        velocity: 127,
                    },
                    {
                        id: 'd2ebd4ac-d73b-468d-a40c-ec27c1b19355',
                        startIRN: 432,
                        key: 74,
                        lengthIRN: 48,
                        velocity: 127,
                    },
                    {
                        id: 'c280b795-2f11-40e9-bbe4-d8f2a2f70a04',
                        startIRN: 432,
                        key: 77,
                        lengthIRN: 48,
                        velocity: 127,
                    },
                    {
                        id: 'b84c6511-57b5-4926-a248-21a6ca4b7c7e',
                        startIRN: 432,
                        key: 81,
                        lengthIRN: 48,
                        velocity: 127,
                    },
                    {
                        id: '4a045136-c18c-46e4-a733-bd6e7ed48bab',
                        startIRN: 480,
                        key: 74,
                        lengthIRN: 48,
                        velocity: 127,
                    },
                    {
                        id: '9d3bd6cb-9cce-4e8e-a10d-398952d876bd',
                        startIRN: 480,
                        key: 77,
                        lengthIRN: 48,
                        velocity: 127,
                    },
                    {
                        id: '4dc52ec4-6983-4072-a3f5-d24963e19dae',
                        startIRN: 480,
                        key: 81,
                        lengthIRN: 48,
                        velocity: 127,
                    },
                    {
                        id: '6ca11a76-5919-4744-810b-e868e7ca1555',
                        startIRN: 528,
                        key: 74,
                        lengthIRN: 48,
                        velocity: 127,
                    },
                    {
                        id: '0ac09579-79a5-49bf-811f-24163ba397b6',
                        startIRN: 528,
                        key: 77,
                        lengthIRN: 48,
                        velocity: 127,
                    },
                    {
                        id: '1e21154a-1830-4e9a-9530-6c6d350a7f76',
                        startIRN: 528,
                        key: 81,
                        lengthIRN: 48,
                        velocity: 127,
                    },
                    {
                        id: 'a6257876-e194-4a58-b91c-5215d632187f',
                        startIRN: 576,
                        key: 74,
                        lengthIRN: 48,
                        velocity: 127,
                    },
                    {
                        id: 'e0a2b164-8cf6-4e40-800c-f90d833617e0',
                        startIRN: 576,
                        key: 77,
                        lengthIRN: 48,
                        velocity: 127,
                    },
                    {
                        id: 'a94621dc-8b44-418a-9d26-29bb26fb5f16',
                        startIRN: 576,
                        key: 81,
                        lengthIRN: 48,
                        velocity: 127,
                    },
                    {
                        id: 'f41663c1-307d-499e-aa67-ae5688372ab8',
                        startIRN: 768,
                        key: 72,
                        lengthIRN: 48,
                        velocity: 127,
                    },
                    {
                        id: 'f3afaa73-542d-4e26-ae4c-dbddabe3e470',
                        startIRN: 768,
                        key: 79,
                        lengthIRN: 48,
                        velocity: 127,
                    },
                    {
                        id: 'fb257529-b9b7-497c-9a2b-090476556d21',
                        startIRN: 816,
                        key: 72,
                        lengthIRN: 48,
                        velocity: 127,
                    },
                    {
                        id: 'd45f436d-a3ea-42f0-bd33-8f34bbee7990',
                        startIRN: 816,
                        key: 79,
                        lengthIRN: 48,
                        velocity: 127,
                    },
                    {
                        id: 'adf122a3-6d26-41ac-b62a-135603101bb2',
                        startIRN: 864,
                        key: 72,
                        lengthIRN: 48,
                        velocity: 127,
                    },
                    {
                        id: '3ffc97a5-2cea-4a3b-b0b4-e38fe79b0a32',
                        startIRN: 864,
                        key: 79,
                        lengthIRN: 48,
                        velocity: 127,
                    },
                    {
                        id: '1a47ef35-5a08-4575-b160-969ab53b9244',
                        startIRN: 912,
                        key: 72,
                        lengthIRN: 48,
                        velocity: 127,
                    },
                    {
                        id: '102cf464-4d5f-4136-9bf3-5282739db7f8',
                        startIRN: 912,
                        key: 79,
                        lengthIRN: 48,
                        velocity: 127,
                    },
                    {
                        id: '676f64bb-7f2d-4a1b-8c4e-56c01ada715e',
                        startIRN: 960,
                        key: 72,
                        lengthIRN: 48,
                        velocity: 127,
                    },
                    {
                        id: 'b6b615b0-0316-4606-aba3-9c0b1a7cee4d',
                        startIRN: 960,
                        key: 79,
                        lengthIRN: 48,
                        velocity: 127,
                    },
                    {
                        id: '3a980c9a-cf6c-4d2c-b3b9-161c18f019fe',
                        startIRN: 1152,
                        key: 67,
                        lengthIRN: 48,
                        velocity: 127,
                    },
                    {
                        id: '5929301e-e8e2-457f-8105-bede9cc5be3c',
                        startIRN: 1152,
                        key: 74,
                        lengthIRN: 48,
                        velocity: 127,
                    },
                    {
                        id: '9396c2b1-c798-4dcf-a380-60735890882d',
                        startIRN: 1200,
                        key: 67,
                        lengthIRN: 48,
                        velocity: 127,
                    },
                    {
                        id: '1e1c1784-99b7-4e4b-9f41-56e807b0b40b',
                        startIRN: 1200,
                        key: 74,
                        lengthIRN: 48,
                        velocity: 127,
                    },
                    {
                        id: 'e6097543-30d9-4cc1-93ac-9eb989383698',
                        startIRN: 1248,
                        key: 67,
                        lengthIRN: 48,
                        velocity: 127,
                    },
                    {
                        id: 'd4f1139d-60bd-4bae-ab17-6304dc324028',
                        startIRN: 1248,
                        key: 74,
                        lengthIRN: 48,
                        velocity: 127,
                    },
                    {
                        id: 'fa79c90b-05b7-457c-8890-36c22c553d29',
                        startIRN: 1296,
                        key: 67,
                        lengthIRN: 48,
                        velocity: 127,
                    },
                    {
                        id: '3f68db78-d17a-467a-849f-17a85c4b60d8',
                        startIRN: 1296,
                        key: 74,
                        lengthIRN: 48,
                        velocity: 127,
                    },
                    {
                        id: 'cc20eb18-0e3a-40e0-abad-95607537e0c2',
                        startIRN: 1344,
                        key: 67,
                        lengthIRN: 48,
                        velocity: 127,
                    },
                    {
                        id: '70a1c1c8-c6d3-48c7-8148-8a3ed480fe7d',
                        startIRN: 1344,
                        key: 74,
                        lengthIRN: 48,
                        velocity: 127,
                    },
                ],
                color: Color.BLUE,
                type: PatternType.PianoRollPattern,
            },
            'ded8bf4e-892a-483b-b634-b14e47fc42e7': {
                id: 'ded8bf4e-892a-483b-b634-b14e47fc42e7',
                lastResolution: 4,
                lengthIRN: 1536,
                notes: [
                    {
                        id: '8df65646-67f6-45b6-a6bd-8533d4a6e23b',
                        key: 36,
                        startIRN: 0,
                        velocity: 127,
                        lengthIRN: 96,
                    },
                    {
                        id: 'dc93afb5-d05b-4101-b275-c5499be3c94c',
                        key: 38,
                        startIRN: 192,
                        velocity: 127,
                        lengthIRN: 96,
                    },
                    {
                        id: '8ec9bb3e-0387-4f6f-81fc-10bfff7bdabb',
                        key: 36,
                        startIRN: 384,
                        velocity: 127,
                        lengthIRN: 96,
                    },
                    {
                        id: '15eb7f0a-9672-4886-a3ec-740e988d2200',
                        key: 38,
                        startIRN: 576,
                        velocity: 127,
                        lengthIRN: 96,
                    },
                    {
                        id: '1f2ecbea-3369-4aa4-8248-23665b03d363',
                        key: 36,
                        startIRN: 768,
                        velocity: 127,
                        lengthIRN: 96,
                    },
                    {
                        id: '0fdcfad4-08a6-43f7-a541-cf48eb7a98bc',
                        key: 38,
                        startIRN: 960,
                        velocity: 127,
                        lengthIRN: 96,
                    },
                    {
                        id: 'fdf395d3-37ad-40b3-94a8-80577272da0b',
                        key: 36,
                        startIRN: 1152,
                        velocity: 127,
                        lengthIRN: 96,
                    },
                    {
                        id: '87944edd-f700-4310-a817-c08cccc841d9',
                        key: 38,
                        startIRN: 1344,
                        velocity: 127,
                        lengthIRN: 96,
                    },
                ],
                color: Color.GREEN,
                type: PatternType.SequencerPattern,
            },
            '9cd2136b-ed6e-4637-abc1-12ac6bf111e9': {
                id: '9cd2136b-ed6e-4637-abc1-12ac6bf111e9',
                lastResolution: 8,
                lengthIRN: 1536,
                notes: [
                    {
                        id: '8361f2b7-8a2d-47a8-af4e-2621189f0682',
                        startIRN: 0,
                        key: 69,
                        lengthIRN: 48,
                        velocity: 127,
                    },
                    {
                        id: '10ef6764-74b6-41ba-a7f2-d65effdb81c7',
                        startIRN: 0,
                        key: 72,
                        lengthIRN: 48,
                        velocity: 127,
                    },
                    {
                        id: 'f4895fcb-f344-4cf4-852a-d900dc5d529a',
                        startIRN: 0,
                        key: 76,
                        lengthIRN: 48,
                        velocity: 127,
                    },
                    {
                        id: 'ec148f53-695a-41ff-87f8-7c096c786a06',
                        startIRN: 48,
                        key: 69,
                        lengthIRN: 48,
                        velocity: 127,
                    },
                    {
                        id: '6d4a8fb2-0ffc-4d4d-a48d-6ef197636f79',
                        startIRN: 48,
                        key: 72,
                        lengthIRN: 48,
                        velocity: 127,
                    },
                    {
                        id: '0c67c5f3-a46a-4af3-9abe-edd9f15cab40',
                        startIRN: 48,
                        key: 76,
                        lengthIRN: 48,
                        velocity: 127,
                    },
                    {
                        id: 'c3894e13-9c28-4391-8dc8-e61fa1381ad5',
                        startIRN: 96,
                        key: 69,
                        lengthIRN: 48,
                        velocity: 127,
                    },
                    {
                        id: 'a3acba14-1236-44cd-a3c7-816ed79fd741',
                        startIRN: 96,
                        key: 72,
                        lengthIRN: 48,
                        velocity: 127,
                    },
                    {
                        id: 'f26b0d41-7602-4f16-bbaa-78837fff5eaa',
                        startIRN: 96,
                        key: 76,
                        lengthIRN: 48,
                        velocity: 127,
                    },
                    {
                        id: '42de240f-7031-4c1d-b1f3-30679958fa07',
                        startIRN: 144,
                        key: 69,
                        lengthIRN: 48,
                        velocity: 127,
                    },
                    {
                        id: '84b0a8c0-0c47-4fb0-919d-9499c744897d',
                        startIRN: 144,
                        key: 72,
                        lengthIRN: 48,
                        velocity: 127,
                    },
                    {
                        id: '1c61bae4-a43b-44b6-b79d-7c94c456429e',
                        startIRN: 144,
                        key: 76,
                        lengthIRN: 48,
                        velocity: 127,
                    },
                    {
                        id: '9f0a143f-4a77-4169-ab9a-4dbbc3528af9',
                        startIRN: 192,
                        key: 69,
                        lengthIRN: 48,
                        velocity: 127,
                    },
                    {
                        id: 'de4f0966-0786-48c5-8907-331acc6aa83e',
                        startIRN: 192,
                        key: 72,
                        lengthIRN: 48,
                        velocity: 127,
                    },
                    {
                        id: 'de0eb9ba-b540-464a-95bd-80a4f0ebdb9a',
                        startIRN: 192,
                        key: 76,
                        lengthIRN: 48,
                        velocity: 127,
                    },
                    {
                        id: '98fc7d47-0ab9-4678-98ee-ffdaa02a8fd0',
                        startIRN: 384,
                        key: 74,
                        lengthIRN: 48,
                        velocity: 127,
                    },
                    {
                        id: '9f6228d7-65e8-4b21-a00b-1702afd9d57d',
                        startIRN: 384,
                        key: 77,
                        lengthIRN: 48,
                        velocity: 127,
                    },
                    {
                        id: '5033e0ef-ea01-4187-950e-c856849fc492',
                        startIRN: 384,
                        key: 81,
                        lengthIRN: 48,
                        velocity: 127,
                    },
                    {
                        id: 'd2ebd4ac-d73b-468d-a40c-ec27c1b19355',
                        startIRN: 432,
                        key: 74,
                        lengthIRN: 48,
                        velocity: 127,
                    },
                    {
                        id: 'c280b795-2f11-40e9-bbe4-d8f2a2f70a04',
                        startIRN: 432,
                        key: 77,
                        lengthIRN: 48,
                        velocity: 127,
                    },
                    {
                        id: 'b84c6511-57b5-4926-a248-21a6ca4b7c7e',
                        startIRN: 432,
                        key: 81,
                        lengthIRN: 48,
                        velocity: 127,
                    },
                    {
                        id: '4a045136-c18c-46e4-a733-bd6e7ed48bab',
                        startIRN: 480,
                        key: 74,
                        lengthIRN: 48,
                        velocity: 127,
                    },
                    {
                        id: '9d3bd6cb-9cce-4e8e-a10d-398952d876bd',
                        startIRN: 480,
                        key: 77,
                        lengthIRN: 48,
                        velocity: 127,
                    },
                    {
                        id: '4dc52ec4-6983-4072-a3f5-d24963e19dae',
                        startIRN: 480,
                        key: 81,
                        lengthIRN: 48,
                        velocity: 127,
                    },
                    {
                        id: '6ca11a76-5919-4744-810b-e868e7ca1555',
                        startIRN: 528,
                        key: 74,
                        lengthIRN: 48,
                        velocity: 127,
                    },
                    {
                        id: '0ac09579-79a5-49bf-811f-24163ba397b6',
                        startIRN: 528,
                        key: 77,
                        lengthIRN: 48,
                        velocity: 127,
                    },
                    {
                        id: '1e21154a-1830-4e9a-9530-6c6d350a7f76',
                        startIRN: 528,
                        key: 81,
                        lengthIRN: 48,
                        velocity: 127,
                    },
                    {
                        id: 'a6257876-e194-4a58-b91c-5215d632187f',
                        startIRN: 576,
                        key: 74,
                        lengthIRN: 48,
                        velocity: 127,
                    },
                    {
                        id: 'e0a2b164-8cf6-4e40-800c-f90d833617e0',
                        startIRN: 576,
                        key: 77,
                        lengthIRN: 48,
                        velocity: 127,
                    },
                    {
                        id: 'a94621dc-8b44-418a-9d26-29bb26fb5f16',
                        startIRN: 576,
                        key: 81,
                        lengthIRN: 48,
                        velocity: 127,
                    },
                    {
                        id: 'f41663c1-307d-499e-aa67-ae5688372ab8',
                        startIRN: 768,
                        key: 72,
                        lengthIRN: 48,
                        velocity: 127,
                    },
                    {
                        id: 'f3afaa73-542d-4e26-ae4c-dbddabe3e470',
                        startIRN: 768,
                        key: 79,
                        lengthIRN: 48,
                        velocity: 127,
                    },
                    {
                        id: 'fb257529-b9b7-497c-9a2b-090476556d21',
                        startIRN: 816,
                        key: 72,
                        lengthIRN: 48,
                        velocity: 127,
                    },
                    {
                        id: 'd45f436d-a3ea-42f0-bd33-8f34bbee7990',
                        startIRN: 816,
                        key: 79,
                        lengthIRN: 48,
                        velocity: 127,
                    },
                    {
                        id: 'adf122a3-6d26-41ac-b62a-135603101bb2',
                        startIRN: 864,
                        key: 72,
                        lengthIRN: 48,
                        velocity: 127,
                    },
                    {
                        id: '3ffc97a5-2cea-4a3b-b0b4-e38fe79b0a32',
                        startIRN: 864,
                        key: 79,
                        lengthIRN: 48,
                        velocity: 127,
                    },
                    {
                        id: '1a47ef35-5a08-4575-b160-969ab53b9244',
                        startIRN: 912,
                        key: 72,
                        lengthIRN: 48,
                        velocity: 127,
                    },
                    {
                        id: '102cf464-4d5f-4136-9bf3-5282739db7f8',
                        startIRN: 912,
                        key: 79,
                        lengthIRN: 48,
                        velocity: 127,
                    },
                    {
                        id: '676f64bb-7f2d-4a1b-8c4e-56c01ada715e',
                        startIRN: 960,
                        key: 72,
                        lengthIRN: 48,
                        velocity: 127,
                    },
                    {
                        id: 'b6b615b0-0316-4606-aba3-9c0b1a7cee4d',
                        startIRN: 960,
                        key: 79,
                        lengthIRN: 48,
                        velocity: 127,
                    },
                    {
                        id: '3a980c9a-cf6c-4d2c-b3b9-161c18f019fe',
                        startIRN: 1152,
                        key: 67,
                        lengthIRN: 48,
                        velocity: 127,
                    },
                    {
                        id: '5929301e-e8e2-457f-8105-bede9cc5be3c',
                        startIRN: 1152,
                        key: 74,
                        lengthIRN: 48,
                        velocity: 127,
                    },
                    {
                        id: '9396c2b1-c798-4dcf-a380-60735890882d',
                        startIRN: 1200,
                        key: 67,
                        lengthIRN: 48,
                        velocity: 127,
                    },
                    {
                        id: '1e1c1784-99b7-4e4b-9f41-56e807b0b40b',
                        startIRN: 1200,
                        key: 74,
                        lengthIRN: 48,
                        velocity: 127,
                    },
                    {
                        id: 'e6097543-30d9-4cc1-93ac-9eb989383698',
                        startIRN: 1248,
                        key: 67,
                        lengthIRN: 48,
                        velocity: 127,
                    },
                    {
                        id: 'd4f1139d-60bd-4bae-ab17-6304dc324028',
                        startIRN: 1248,
                        key: 74,
                        lengthIRN: 48,
                        velocity: 127,
                    },
                    {
                        id: 'fa79c90b-05b7-457c-8890-36c22c553d29',
                        startIRN: 1296,
                        key: 67,
                        lengthIRN: 48,
                        velocity: 127,
                    },
                    {
                        id: '3f68db78-d17a-467a-849f-17a85c4b60d8',
                        startIRN: 1296,
                        key: 74,
                        lengthIRN: 48,
                        velocity: 127,
                    },
                    {
                        id: 'cc20eb18-0e3a-40e0-abad-95607537e0c2',
                        startIRN: 1344,
                        key: 67,
                        lengthIRN: 48,
                        velocity: 127,
                    },
                    {
                        id: '70a1c1c8-c6d3-48c7-8148-8a3ed480fe7d',
                        startIRN: 1344,
                        key: 74,
                        lengthIRN: 48,
                        velocity: 127,
                    },
                ],
                color: Color.CYAN,
                type: PatternType.PianoRollPattern,
            },
            '8ce92bc2-b0b5-473f-8db4-1bbe571d8c38': {
                id: '8ce92bc2-b0b5-473f-8db4-1bbe571d8c38',
                lastResolution: 8,
                lengthIRN: 1536,
                notes: [
                    {
                        id: 'd3a10eb8-31ad-4523-94a1-095957a79c0f',
                        startIRN: 0,
                        key: 60,
                        lengthIRN: 48,
                        velocity: 127,
                    },
                    {
                        id: '10d6dfb6-797a-47e9-87e1-6464cacdf821',
                        startIRN: 48,
                        key: 62,
                        lengthIRN: 48,
                        velocity: 127,
                    },
                    {
                        id: 'f8810fae-b958-4c0b-906a-a9f1537b291b',
                        startIRN: 192,
                        key: 67,
                        lengthIRN: 48,
                        velocity: 127,
                    },
                    {
                        id: 'f87b44f8-c34a-4562-abb1-3537772d394c',
                        startIRN: 240,
                        key: 69,
                        lengthIRN: 48,
                        velocity: 127,
                    },
                    {
                        id: 'e3fe050d-1633-427d-a645-e81543369872',
                        startIRN: 384,
                        key: 64,
                        lengthIRN: 48,
                        velocity: 127,
                    },
                    {
                        id: '68cb7dfe-8b85-4221-b3a8-d4777ee48fc1',
                        startIRN: 432,
                        key: 67,
                        lengthIRN: 48,
                        velocity: 127,
                    },
                    {
                        id: '71cd8c3d-7757-40bc-b267-25243664ce40',
                        startIRN: 576,
                        key: 67,
                        lengthIRN: 48,
                        velocity: 127,
                    },
                    {
                        id: '584f6b6f-d1a8-4c3e-bedf-ce5481786b4c',
                        startIRN: 624,
                        key: 64,
                        lengthIRN: 48,
                        velocity: 127,
                    },
                    {
                        id: 'a0586fed-950e-4f1c-a354-fd86d92a578a',
                        startIRN: 768,
                        key: 64,
                        lengthIRN: 48,
                        velocity: 127,
                    },
                    {
                        id: '534f2647-2c65-414d-bb34-66c6da6719f1',
                        startIRN: 816,
                        key: 64,
                        lengthIRN: 48,
                        velocity: 127,
                    },
                    {
                        id: 'c766edec-1543-4301-af8f-a11bf8dc5802',
                        startIRN: 864,
                        key: 69,
                        lengthIRN: 48,
                        velocity: 127,
                    },
                    {
                        id: 'f9730efa-53af-436a-8ef0-4a5f3d9f2fba',
                        startIRN: 912,
                        key: 67,
                        lengthIRN: 48,
                        velocity: 127,
                    },
                    {
                        id: '6618409d-5365-4bee-aa91-89baf3419d1f',
                        startIRN: 1056,
                        key: 64,
                        lengthIRN: 48,
                        velocity: 127,
                    },
                    {
                        id: '9fe9d589-b87a-4a0a-b646-f7c1f257c96b',
                        startIRN: 1104,
                        key: 67,
                        lengthIRN: 48,
                        velocity: 127,
                    },
                    {
                        id: 'b429a84f-9c26-4566-86ad-83e0d4df31f7',
                        startIRN: 1296,
                        key: 72,
                        lengthIRN: 48,
                        velocity: 127,
                    },
                    {
                        id: 'af4bcdfa-52ed-45d2-9178-e2c6a243799f',
                        startIRN: 1344,
                        key: 72,
                        lengthIRN: 48,
                        velocity: 127,
                    },
                    {
                        id: 'd0302be7-d1f6-49ed-86e1-d798af60b1cb',
                        startIRN: 1440,
                        key: 69,
                        lengthIRN: 48,
                        velocity: 127,
                    },
                    {
                        id: 'f5b233f4-5d44-4a44-add1-b1735d8150dc',
                        startIRN: 1488,
                        key: 67,
                        lengthIRN: 48,
                        velocity: 127,
                    },
                ],
                color: Color.GREEN,
                type: PatternType.PianoRollPattern,
            },
        },
    },
    tracks: {
        ids: [
            '417e6785-619d-4338-aaac-b02df1fdcd79',
            'a8b5702d-76df-4716-97b4-a8ad5cb9cf3d',
            '737f567b-9077-4079-9d30-70ef4a15c8de',
        ],
        entities: {
            '417e6785-619d-4338-aaac-b02df1fdcd79': {
                id: '417e6785-619d-4338-aaac-b02df1fdcd79',
                channel: 9,
                instrument: Instruments.SYNTH_DRUM,
                volume: 64,
                solo: false,
                mute: false,
                patterns: {
                    '599d2523-364a-4a53-a46a-da24b75dd7c2': {
                        id: '599d2523-364a-4a53-a46a-da24b75dd7c2',
                        patternId: 'ded8bf4e-892a-483b-b634-b14e47fc42e7',
                        position: 7680,
                        startAt: 0,
                        length: 1536,
                    },
                    '567431f5-314b-4428-a9af-bdc765c0bb28': {
                        id: '567431f5-314b-4428-a9af-bdc765c0bb28',
                        patternId: '93b0c020-1a26-4f15-a65a-7b3b45b73de5',
                        position: 4608,
                        startAt: 0,
                        length: 1536,
                    },
                    'a7fad74c-cc0a-4345-9d19-4f220da8b3d3': {
                        id: 'a7fad74c-cc0a-4345-9d19-4f220da8b3d3',
                        patternId: '1c03d821-b9df-486b-898b-d07967df8a25',
                        position: 3072,
                        startAt: 0,
                        length: 1536,
                    },
                    '43605eef-f096-4c36-9d28-9c68d409bac4': {
                        id: '43605eef-f096-4c36-9d28-9c68d409bac4',
                        patternId: 'e11a726a-bba2-4df7-91ff-74cd99357255',
                        position: 1536,
                        startAt: 0,
                        length: 1536,
                    },
                },
            },
            'a8b5702d-76df-4716-97b4-a8ad5cb9cf3d': {
                id: 'a8b5702d-76df-4716-97b4-a8ad5cb9cf3d',
                channel: 1,
                instrument: Instruments.ACOUSTIC_GUITAR_NYLON,
                volume: 64,
                solo: false,
                mute: false,
                patterns: {
                    'a51ac336-ca19-4cd3-a42c-938f18e2de5c': {
                        id: 'a51ac336-ca19-4cd3-a42c-938f18e2de5c',
                        patternId: '9cd2136b-ed6e-4637-abc1-12ac6bf111e9',
                        position: 7680,
                        startAt: 0,
                        length: 1536,
                    },
                    '840892d0-a92e-44a3-b6d0-53a3fdbd450c': {
                        id: '840892d0-a92e-44a3-b6d0-53a3fdbd450c',
                        patternId: 'e09f69d5-f516-4784-94f7-3d29be2840ef',
                        position: 6144,
                        startAt: 0,
                        length: 1536,
                    },
                    'f0585a5c-9b3d-4e0b-ab3f-11cc3c3d7abe': {
                        id: 'f0585a5c-9b3d-4e0b-ab3f-11cc3c3d7abe',
                        patternId: '8462bca7-25c8-479b-9441-404908c6dea1',
                        position: 4608,
                        startAt: 0,
                        length: 1536,
                    },
                    'b3b57ce5-d073-4c7d-93c5-ac0920c2bb3b': {
                        id: 'b3b57ce5-d073-4c7d-93c5-ac0920c2bb3b',
                        patternId: '44b1d986-fff2-4ab8-b98d-49f6de6c806b',
                        position: 3072,
                        startAt: 0,
                        length: 1536,
                    },
                    '5229c4d0-6149-4a84-9490-83819ea814d5': {
                        id: '5229c4d0-6149-4a84-9490-83819ea814d5',
                        patternId: 'e6bb125f-95b4-46aa-9b6c-2d2a89cbcb80',
                        position: 1536,
                        startAt: 0,
                        length: 1536,
                    },
                    '7f8c90bf-6ac5-4d4a-9516-812f3ca8ba72': {
                        id: '7f8c90bf-6ac5-4d4a-9516-812f3ca8ba72',
                        patternId: '963b3e49-ec32-4673-9dec-bfc9f5d70feb',
                        position: 0,
                        startAt: 0,
                        length: 1536,
                    },
                },
            },
            '737f567b-9077-4079-9d30-70ef4a15c8de': {
                id: '737f567b-9077-4079-9d30-70ef4a15c8de',
                channel: 2,
                instrument: Instruments.ACOUSTIC_GRAND_PIANO,
                volume: 64,
                solo: false,
                mute: false,
                patterns: {
                    '807a8649-e654-4bfb-979a-5a1feb833c55': {
                        id: '807a8649-e654-4bfb-979a-5a1feb833c55',
                        patternId: '8ce92bc2-b0b5-473f-8db4-1bbe571d8c38',
                        position: 7680,
                        startAt: 0,
                        length: 1536,
                    },
                    '692ff326-372f-4000-a74b-f269002396c1': {
                        id: '692ff326-372f-4000-a74b-f269002396c1',
                        patternId: '30c1946d-12cf-4750-9a8c-f6eae09eb0a8',
                        position: 4608,
                        startAt: 0,
                        length: 1536,
                    },
                    '62a7fc5b-5e34-431b-8bc7-6454ad872fb2': {
                        id: '62a7fc5b-5e34-431b-8bc7-6454ad872fb2',
                        patternId: '6fc0d179-1428-451e-a78d-6976abba1e4a',
                        position: 3072,
                        startAt: 0,
                        length: 1536,
                    },
                },
            },
        },
    },
}
