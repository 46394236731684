import { EntityAdapter, EntityState } from '@ngrx/entity/src/models'
import { Note } from './project-state.model'
import { Instruments } from '../midi/Instruments'
import { createEntityAdapter } from '@ngrx/entity'

export interface DrumSequencerNotesState extends EntityState<Note> {}

export const drumSequencerNotesAdapter: EntityAdapter<Note> = createEntityAdapter<Note>({})

export interface DrumSequencerStateModel {
    patternId: string
    instrument: Instruments
    channel: number
    notes: DrumSequencerNotesState
}

export const initialDrumSequencerState = {
    patternId: '',
    instrument: Instruments.SYNTH_DRUM,
    channel: 0,
    notes: drumSequencerNotesAdapter.getInitialState(),
}
