export enum Instruments {
    ACOUSTIC_GRAND_PIANO = 'acoustic_grand_piano',
    BRIGHT_ACOUSTIC_PIANO = 'bright_acoustic_piano',
    ELECTRIC_GRAND_PIANO = 'electric_grand_piano',
    HONKYTONK_PIANO = 'honkytonk_piano',
    ELECTRIC_PIANO_1 = 'electric_piano_1',
    ELECTRIC_PIANO_2 = 'electric_piano_2',
    HARPSICHORD = 'harpsichord',
    CLAVINET = 'clavinet',
    CELESTA = 'celesta',
    GLOCKENSPIEL = 'glockenspiel',
    MUSIC_BOX = 'music_box',
    VIBRAPHONE = 'vibraphone',
    MARIMBA = 'marimba',
    XYLOPHONE = 'xylophone',
    TUBULAR_BELLS = 'tubular_bells',
    DULCIMER = 'dulcimer',
    DRAWBAR_ORGAN = 'drawbar_organ',
    PERCUSSIVE_ORGAN = 'percussive_organ',
    ROCK_ORGAN = 'rock_organ',
    CHURCH_ORGAN = 'church_organ',
    REED_ORGAN = 'reed_organ',
    ACCORDION = 'accordion',
    HARMONICA = 'harmonica',
    TANGO_ACCORDION = 'tango_accordion',
    ACOUSTIC_GUITAR_NYLON = 'acoustic_guitar_nylon',
    ACOUSTIC_GUITAR_STEEL = 'acoustic_guitar_steel',
    ELECTRIC_GUITAR_JAZZ = 'electric_guitar_jazz',
    ELECTRIC_GUITAR_CLEAN = 'electric_guitar_clean',
    ELECTRIC_GUITAR_MUTED = 'electric_guitar_muted',
    OVERDRIVEN_GUITAR = 'overdriven_guitar',
    DISTORTION_GUITAR = 'distortion_guitar',
    GUITAR_HARMONICS = 'guitar_harmonics',
    ACOUSTIC_BASS = 'acoustic_bass',
    ELECTRIC_BASS_FINGER = 'electric_bass_finger',
    ELECTRIC_BASS_PICK = 'electric_bass_pick',
    FRETLESS_BASS = 'fretless_bass',
    SLAP_BASS_1 = 'slap_bass_1',
    SLAP_BASS_2 = 'slap_bass_2',
    SYNTH_BASS_1 = 'synth_bass_1',
    SYNTH_BASS_2 = 'synth_bass_2',
    VIOLIN = 'violin',
    VIOLA = 'viola',
    CELLO = 'cello',
    CONTRABASS = 'contrabass',
    TREMOLO_STRINGS = 'tremolo_strings',
    PIZZICATO_STRINGS = 'pizzicato_strings',
    ORCHESTRAL_HARP = 'orchestral_harp',
    TIMPANI = 'timpani',
    STRING_ENSEMBLE_1 = 'string_ensemble_1',
    STRING_ENSEMBLE_2 = 'string_ensemble_2',
    SYNTH_STRINGS_1 = 'synth_strings_1',
    SYNTH_STRINGS_2 = 'synth_strings_2',
    CHOIR_AAHS = 'choir_aahs',
    VOICE_OOHS = 'voice_oohs',
    SYNTH_CHOIR = 'synth_choir',
    ORCHESTRA_HIT = 'orchestra_hit',
    TRUMPET = 'trumpet',
    TROMBONE = 'trombone',
    TUBA = 'tuba',
    MUTED_TRUMPET = 'muted_trumpet',
    FRENCH_HORN = 'french_horn',
    BRASS_SECTION = 'brass_section',
    SYNTH_BRASS_1 = 'synth_brass_1',
    SYNTH_BRASS_2 = 'synth_brass_2',
    SOPRANO_SAX = 'soprano_sax',
    ALTO_SAX = 'alto_sax',
    TENOR_SAX = 'tenor_sax',
    BARITONE_SAX = 'baritone_sax',
    OBOE = 'oboe',
    ENGLISH_HORN = 'english_horn',
    BASSOON = 'bassoon',
    CLARINET = 'clarinet',
    PICCOLO = 'piccolo',
    FLUTE = 'flute',
    RECORDER = 'recorder',
    PAN_FLUTE = 'pan_flute',
    BLOWN_BOTTLE = 'blown_bottle',
    SHAKUHACHI = 'shakuhachi',
    WHISTLE = 'whistle',
    OCARINA = 'ocarina',
    LEAD_1_SQUARE = 'lead_1_square',
    LEAD_2_SAWTOOTH = 'lead_2_sawtooth',
    LEAD_3_CALLIOPE = 'lead_3_calliope',
    LEAD_4_CHIFF = 'lead_4_chiff',
    LEAD_5_CHARANG = 'lead_5_charang',
    LEAD_6_VOICE = 'lead_6_voice',
    LEAD_7_FIFTHS = 'lead_7_fifths',
    LEAD_8_BASS__LEAD = 'lead_8_bass__lead',
    PAD_1_NEW_AGE = 'pad_1_new_age',
    PAD_2_WARM = 'pad_2_warm',
    PAD_3_POLYSYNTH = 'pad_3_polysynth',
    PAD_4_CHOIR = 'pad_4_choir',
    PAD_5_BOWED = 'pad_5_bowed',
    PAD_6_METALLIC = 'pad_6_metallic',
    PAD_7_HALO = 'pad_7_halo',
    PAD_8_SWEEP = 'pad_8_sweep',
    FX_1_RAIN = 'fx_1_rain',
    FX_2_SOUNDTRACK = 'fx_2_soundtrack',
    FX_3_CRYSTAL = 'fx_3_crystal',
    FX_4_ATMOSPHERE = 'fx_4_atmosphere',
    FX_5_BRIGHTNESS = 'fx_5_brightness',
    FX_6_GOBLINS = 'fx_6_goblins',
    FX_7_ECHOES = 'fx_7_echoes',
    FX_8_SCIFI = 'fx_8_scifi',
    SITAR = 'sitar',
    BANJO = 'banjo',
    SHAMISEN = 'shamisen',
    KOTO = 'koto',
    KALIMBA = 'kalimba',
    BAGPIPE = 'bagpipe',
    FIDDLE = 'fiddle',
    SHANAI = 'shanai',
    TINKLE_BELL = 'tinkle_bell',
    AGOGO = 'agogo',
    STEEL_DRUMS = 'steel_drums',
    WOODBLOCK = 'woodblock',
    TAIKO_DRUM = 'taiko_drum',
    MELODIC_TOM = 'melodic_tom',
    SYNTH_DRUM = 'synth_drum',
    REVERSE_CYMBAL = 'reverse_cymbal',
    GUITAR_FRET_NOISE = 'guitar_fret_noise',
    BREATH_NOISE = 'breath_noise',
    SEASHORE = 'seashore',
    BIRD_TWEET = 'bird_tweet',
    TELEPHONE_RING = 'telephone_ring',
    HELICOPTER = 'helicopter',
    APPLAUSE = 'applause',
    GUNSHOT = 'gunshot',
}
